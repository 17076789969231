import {
    REQUEST_RETRIEVE_CURRENT_USER,
    RETRIEVE_CURRENT_USER_SUCCESS,
    RETRIEVE_CURRENT_USER_FAIL,
    REQUEST_RETRIEVE_EXPLORER,
    RETRIEVE_EXPLORER_SUCCESS,
    RETRIEVE_EXPLORER_FAIL,
    REQUEST_RETRIEVE_FORYOU,
    RETRIEVE_FORYOU_SUCCESS,
    RETRIEVE_FORYOU_FAIL,
    REQUEST_RETRIEVE_CONTACTS,
    RETRIEVE_CONTACTS_SUCCESS,
    RETRIEVE_CONTACTS_FAIL,
    REQUEST_RETRIEVE_SUGGESTION_PEOPLE,
    RETRIEVE_SUGGESTION_PEOPLE_SUCCESS,
    RETRIEVE_SUGGESTION_PEOPLE_FAIL,
    REQUEST_PREVIEWS_URL,
    RETRIEVE_PREVIEWS_URL_SUCCESS,
    RETRIEVE_PREVIEWS_URL_FAIL,
    REQUEST_RETRIEVE_MEMORIES,
    RETRIEVE_MEMORIES_SUCCESS
} from "../../../actions/home/types";

const initialState = {
    currentUser: null,

    isLoadingExplorer:false,
    explorerList: [],
    hasMoreDataExplorer: true,
    loadingMoreExplorer: false,

    isLoadingForYou:false,
    forYouList: [],
    hasMoreDataForYou:true,
    loadingMoreForYou:false,


    contactsList: [],
    suggestionPeopleList: [],
    memoriesList: [],
    isLoading: false,
    success_previews: false,
    fail_previews: false,
    previews_loading: false,
    previews_data: null
}

const homeReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case REQUEST_RETRIEVE_CURRENT_USER:
            return {
                ...state,
                isLoading: true,
                currentUser: null,
            };
        case RETRIEVE_CURRENT_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                currentUser: payload,
            };
        case RETRIEVE_CURRENT_USER_FAIL:
            return {
                ...state,
                isLoading: false,
                currentUser: null,
            };
        case REQUEST_RETRIEVE_EXPLORER:
            return {
                ...state,
                isLoadingExplorer: true,
                loadingMoreExplorer: true,
            };
        case RETRIEVE_EXPLORER_SUCCESS:
            return {
                ...state,
                isLoadingExplorer: false,
                loadingMoreExplorer: false,
                explorerList: state.explorerList.concat(payload.data),
                hasMoreDataExplorer: payload.hasMore,
            };
        case RETRIEVE_EXPLORER_FAIL:
            return {
                ...state,
                isLoadingExplorer: false,
                loadingMoreExplorer: false,
            };
        case REQUEST_RETRIEVE_FORYOU:
            return {
                ...state,
                isLoadingForYou:true,
                loadingMoreForYou:true,
            };
        case RETRIEVE_FORYOU_SUCCESS:
            return {
                ...state,
                isLoadingForYou: false,
                loadingMoreForYou:false,
                forYouList: state.forYouList.concat(payload.data),
                hasMoreDataForYou:payload.hasMore
            };
        case RETRIEVE_FORYOU_FAIL:
            return {
                ...state,
                isLoadingForYou: false,
                loadingMoreForYou:false
            };
        case REQUEST_RETRIEVE_CONTACTS:
            return {
                ...state,
                isLoading: true,
            };
        case RETRIEVE_CONTACTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                contactsList: payload,
            };
        case RETRIEVE_CONTACTS_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        case REQUEST_RETRIEVE_SUGGESTION_PEOPLE:
            return {
                ...state,
                isLoading: true,
            };
        case RETRIEVE_SUGGESTION_PEOPLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                suggestionPeopleList: payload,
            };
        case RETRIEVE_SUGGESTION_PEOPLE_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        case REQUEST_PREVIEWS_URL:
            return {
                ...state,
                previews_loading: true,
            };
        case RETRIEVE_PREVIEWS_URL_SUCCESS:
            return {
                ...state,
                previews_loading: false,
                success_previews: true,
                previews_data: payload,
            };
        case RETRIEVE_PREVIEWS_URL_FAIL:
            return {
                ...state,
                previews_loading: false,
                fail_previews: true,
            };
        case REQUEST_RETRIEVE_MEMORIES:
            return {
                ...state,
                isLoading: true,
            };
        case RETRIEVE_MEMORIES_SUCCESS:
            return {
                ...state,
                memoriesList: payload,
                isLoading: false,
            };
        default:
            return state;
    }
}

export default homeReducer;
