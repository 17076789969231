import axios from 'axios';
import authHeader from '../auth-header';
import { API_URL } from "../base_url";

class ExplorerService {

    getExplorer = async (page = 1) => {
        return axios.get(`${API_URL}/explorer?page=${page}`, { headers: authHeader() });
    };
}

export default new ExplorerService;
