import axios from 'axios';
import authHeader from '../auth-header';
import { API_URL } from '../base_url';

const getAllStories = () => {
    return axios.get(API_URL + '/stories', { headers: authHeader() });
};

// const getAudienceStories = id => {
//     return http.get('/stories/' + id + '/audience');
// };

// const getUsersStories = users_id => {
//     return http.get('/users/' + users_id + '/stories');
// };

// const createStoriesBackground = data => {
//     return http.post('/stories/store-background-stories', data);
// };

// const createStoriesVideos = data => {
//     return http.post('/stories/store-videos-stories', data);
// };

// const createStoriesImages = data => {
//     return http.post('/stories/store-images-stories', data);
// };

// const deleteStories = id => {
//     return http.delete('/stories/' + id + '/delete');
// };

const StoriesService = {
    getAllStories,
    // getAudienceStories,
    // getUsersStories,
    // createStoriesBackground,
    // createStoriesVideos,
    // createStoriesImages,
    // deleteStories
}

export default StoriesService;