import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const MAPBOX_TOKEN = 'pk.eyJ1IjoiZGVkZWFyeWEiLCJhIjoiY2pydDFudnZnMDByYzQ5cmpvbGhxMzEycyJ9.z4uM9Gd52HriRATvEMPm3Q';

const DEFAULT_LATITUDE = 0;
const DEFAULT_LONGITUDE = 0;

const MapComponent = ({ latitude = DEFAULT_LATITUDE, longitude = DEFAULT_LONGITUDE }) => {
    const mapContainerRef = useRef(null);

    useEffect(() => {
        mapboxgl.accessToken = MAPBOX_TOKEN;
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [longitude, latitude],
            zoom: 12,
            interactive: false,
            attributionControl: false
        });

        map.scrollZoom.disable();

        return () => map.remove();
    }, [latitude, longitude]);

    return (
        <div className="relative w-full h-full">
            <div ref={mapContainerRef} className="w-full h-[400px]"></div>
        </div>
    );
};

export default MapComponent;
