import React from 'react';
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThemeContext } from './ThemeContext.js';

const ToggleMobile = () => {
    const { theme, setTheme } = React.useContext(ThemeContext);
    return (
        <div
            className="t">
            {theme === 'dark' ? (
                <FontAwesomeIcon icon={faSun}
                    onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                    className="text-gray-600 dark:text-dark-txt text-xl"
                />
            ) : (
                <FontAwesomeIcon icon={faMoon}
                    onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                    className="text-gray-600 dark:text-dark-txt text-xl"
                />
            )}
        </div>
    );
};

export default ToggleMobile;