import axios from 'axios';
import authHeader from './auth-header';
import { API_URL } from "./base_url";

class UserService {
    getAuthenticatedUser() {
        return axios.get(API_URL + '/auth/getAuthenticatedUser', { headers: authHeader() });
    }
}

export default new UserService();