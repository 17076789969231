import {
    REQUEST_REDIRECT,
    REDIRECT_SUCCESS,
    REDIRECT_FAIL,
    REQUEST_CALLBACK,
    CALLBACK_SUCCESS,
    CALLBACK_FAIL,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    RESET_SUCCESS,
    RESET_FAIL,
    LOGOUT,
} from "../../actions/auth/types";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
    isLoadingRedirect: false,
    url: null,
    isLoadingcallback: false,
    isLoggedIn: user ? true : false,
    user: user || null,
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REQUEST_REDIRECT:
            return {
                ...state,
                isLoadingRedirect: true
            }
        case REDIRECT_SUCCESS:
            return {
                ...state,
                url: payload,
                isLoadingRedirect: false
            }
        case REDIRECT_FAIL:
            return {
                ...state,
                isLoadingRedirect: false
            }
        case REQUEST_CALLBACK:
            return {
                ...state,
                isLoadingcallback: true
            }
        case CALLBACK_SUCCESS:
            return {
                ...state,
                isLoadingcallback: false
            }
        case CALLBACK_FAIL:
            return {
                ...state,
                isLoadingcallback: false
            }
        case REGISTER_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
            };
        case REGISTER_FAIL:
            return {
                ...state,
                isLoggedIn: false,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
            }
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case RESET_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
            };
        case RESET_FAIL:
            return {
                ...state,
                isLoggedIn: false,
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        default:
            return state;
    }
}