import React from 'react';
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThemeContext } from './ThemeContext.js';
import { Tooltip } from 'react-tooltip';

const Toggle = () => {
    const { theme, setTheme } = React.useContext(ThemeContext);
    return (
        <div 
        data-tooltip-id="theme"
        data-tooltip-content="Switch Theme"
        data-tooltip-place="bottom"
         className="text-sm text-gray-600 grid place-items-center bg-white dark:bg-dark-third dark:text-dark-txt rounded-full mx-1 p-3 cursor-pointer hover:bg-gray-100 relative">
            {theme === 'dark' ? (
                <FontAwesomeIcon icon={faSun}
                    onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                    className="text-gray-500 dark:text-gray-400 text-2xl px-1 cursor-pointer"
                />
            ) : (
                <FontAwesomeIcon icon={faMoon}
                    onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                    className="text-gray-500 dark:text-gray-400 text-2xl px-1 cursor-pointer"
                />
            )}
           <Tooltip id='theme'/>
        </div>
    );
};

export default Toggle;