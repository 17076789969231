export const REQUEST_REDIRECT = "REQUEST_REDIRECT";
export const REDIRECT_SUCCESS = "REDIRECT_SUCCESS";
export const REDIRECT_FAIL = "REDIRECT_FAIL";

export const REQUEST_CALLBACK = "REQUEST_CALLBACK";
export const CALLBACK_SUCCESS = "CALLBACK_SUCCESS";
export const CALLBACK_FAIL = "CALLBACK_FAIL";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_FAIL = "RESET_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
