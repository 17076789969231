import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/images/icon.png';
import FtProfil from '../../../assets/images/new/tuat.jpg';
import { faSearch, faHome, faBook, faImages, faStore, faUser, faBookOpen, faNewspaper, faCompass, faTh, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from 'react-i18next';
import Toggle from '../util/ThemeToggle.js';


class BottomNavigationWidget extends Component {
    render() {
        const { t } = this.props
        return (
            <div className='xl:hidden md:hidden 2xl:hidden'>
                <nav className="bg-white dark:bg-dark-second h-max md:h-14 w-full shadow flex flex-col md:flex-row items-center justify-stretch md:justify-between fixed bottom-0 z-50 border-t dark:border-dark-third">
                    <ul className="flex w-full lg:w-max items-center justify-stretch">
                        <li className="flex-1 text-center">
                            <Link to="/home" data-tooltip-target="tooltip-bottom" data-tooltip-trigger="hover" data-tooltip-placement="bottom" className="w-full text-2xl py-2 px-3 xl:px-12 cursor-pointer text-center inline-block rounded text-gray-600 hover:bg-gray-100 dark:hover:bg-dark-third dark:text-dark-txt relative">
                                <FontAwesomeIcon icon={faNewspaper} />
                            </Link>
                            <div id="tooltip-bottom" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                Beranda
                                <div className="tooltip-arrow" data-popper-arrow></div>
                            </div>
                        </li>
                        <li className="flex-1 text-center">
                            <Link to="/explorer" data-tooltip-target="tooltip-explorer" data-tooltip-trigger="hover" data-tooltip-placement="bottom" className="w-full text-2xl py-2 px-3 xl:px-12 cursor-pointer text-center inline-block rounded text-gray-600 hover:bg-gray-100 dark:hover:bg-dark-third dark:text-dark-txt relative">
                                <FontAwesomeIcon icon={faCompass} />
                            </Link>
                            <div id="tooltip-explorer" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                Explorer
                                <div className="tooltip-arrow" data-popper-arrow></div>
                            </div>
                        </li>
                        <li className="flex-1 text-center">
                            <Link to="/explorer" data-tooltip-target="tooltip-explorer" data-tooltip-trigger="hover" data-tooltip-placement="bottom" className="w-full text-2xl py-2 px-3 xl:px-12 cursor-pointer text-center inline-block rounded text-gray-600 hover:bg-gray-100 dark:hover:bg-dark-third dark:text-dark-txt relative">
                                <FontAwesomeIcon icon={faTh} />
                            </Link>
                            <div id="tooltip-explorer" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                Explorer
                                <div className="tooltip-arrow" data-popper-arrow></div>
                            </div>
                        </li>
                        <li className="flex-1 text-center">
                            <Link to="/watch" data-tooltip-target="tooltip-resto" data-tooltip-trigger="hover" data-tooltip-placement="bottom" className="w-full text-2xl py-2 px-3 xl:px-12 cursor-pointer text-center inline-block rounded text-gray-600 hover:bg-gray-100 dark:hover:bg-dark-third dark:text-dark-txt relative">
                                <FontAwesomeIcon icon={faPlayCircle} />
                            </Link>
                            <div id="tooltip-resto" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                Moments
                                <div className="tooltip-arrow" data-popper-arrow></div>
                            </div>
                        </li>
                        <li className="flex-1 text-center">
                            <Link to="/notification" data-tooltip-target="tooltip-notification" data-tooltip-trigger="hover" data-tooltip-placement="bottom" className="w-full text-2xl py-2 px-3 xl:px-12 cursor-pointer text-center inline-block rounded text-gray-600 hover:bg-gray-100 dark:hover:bg-dark-third dark:text-dark-txt relative">
                                <FontAwesomeIcon icon={faUser} />
                            </Link>
                            <div id="tooltip-notification" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                Notification
                                <div class="tooltip-arrow" data-popper-arrow></div>
                            </div>
                        </li>
                    </ul>
                    {/* <!-- END MAIN NAV --> */}


                </nav>
            </div>
        );
    }
}

export default BottomNavigationWidget