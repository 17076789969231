import { faClock, faEdit, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
class NoteWidget extends Component {
    render() {
        const { t } = this.props
        return (
            <Link to="/">
                <div className="shadow bg-white dark:bg-dark-second dark:text-dark-txt mt-4 rounded-none lg:rounded-lg xl:rounded-lg">
                    <div className='flex items-center justify-between px-4 py-2'>
                        <div className='flex'>
                            <p>Latihan</p>
                        </div>
                        <div className='flex'>
                            <Link to="/notes/edit">
                                <div className="w-8 h-8 grid place-items-center text-xl text-gray-500 hover:bg-gray-200 dark:text-dark-txt dark:hover:bg-dark-third rounded-full cursor-pointer">
                                    <FontAwesomeIcon icon={faEdit} className="text-gray-400 text-sm" />
                                </div>
                            </Link>
                            <div className="w-8 h-8 grid place-items-center text-xl text-gray-500 hover:bg-gray-200 dark:text-dark-txt dark:hover:bg-dark-third rounded-full cursor-pointer">
                                <FontAwesomeIcon icon={faTrash} className="text-gray-400 text-sm" />
                            </div>
                        </div>
                    </div>
                    <div className='px-4 pb-2 text-xs'>
                        <p>Latihan membuat aplikasi aplikasi sederhana menggunakan bootstrap</p>
                        <div className='mt-2'>
                            <FontAwesomeIcon icon={faClock} className="text-gray-400 text-sm mr-2" /><span>22-08-2022</span>
                        </div>
                    </div>
                </div>
            </Link>

        );
    }
}
export default withTranslation()(NoteWidget)