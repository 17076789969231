export const REQUEST_RETRIEVE_ALL_POSTS = "REQUEST_RETRIEVE_ALL_POSTS";
export const RETRIEVE_ALL_POSTS_SUCCESS = "RETRIEVE_ALL_POSTS_SUCCESS";
export const RETRIEVE_ALL_POSTS_FAIL = "RETRIEVE_ALL_POSTS_FAIL";

export const REQUEST_RETRIEVE_POPULAR_POSTS = "REQUEST_RETRIEVE_POPULAR_POSTS";
export const RETRIEVE_POPULAR_POSTS_SUCCESS = "RETRIEVE_POPULAR_POSTS_SUCCESS";
export const RETRIEVE_POPULAR_POSTS_FAIL = "RETRIEVE_POPULAR_POSTS_FAIL";

export const REQUEST_RETRIEVE_TVS_POSTS = "REQUEST_RETRIEVE_TVS_POSTS";
export const RETRIEVE_TVS_POSTS_SUCCESS = "RETRIEVE_TVS_POSTS_SUCCESS";
export const RETRIEVE_TVS_POSTS_FAIL = "RETRIEVE_TVS_POSTS_FAIL";

export const REQUEST_RETRIEVE_VIDEOS_POSTS = "REQUEST_RETRIEVE_VIDEOS_POSTS";
export const RETRIEVE_VIDEOS_POSTS_SUCCESS = "RETRIEVE_VIDEOS_POSTS_SUCCESS";
export const RETRIEVE_VIDEOS_POSTS_FAIL = "RETRIEVE_VIDEOS_POSTS_FAIL";

export const REQUEST_RETRIEVE_BG_POSTS = "REQUEST_RETRIEVE_BG_POSTS";
export const RETRIEVE_BG_POSTS_SUCCESS = "RETRIEVE_BG_POSTS_SUCCESS";
export const RETRIEVE_BG_POSTS_FAIL = "RETRIEVE_BG_POSTS_FAIL";

export const REQUEST_RETRIEVE_LINK_POSTS = "REQUEST_RETRIEVE_LINK_POSTS";
export const RETRIEVE_LINK_POSTS_SUCCESS = "RETRIEVE_LINK_POSTS_SUCCESS";
export const RETRIEVE_LINK_POSTS_FAIL = "RETRIEVE_LINK_POSTS_FAIL";

export const REQUEST_RETRIEVE_USERS_POSTS = "REQUEST_RETRIEVE_USERS_POSTS";
export const RETRIEVE_USERS_POSTS_SUCCESS = "RETRIEVE_USERS_POSTS_SUCCESS";
export const RETRIEVE_USERS_POSTS_FAIL = "RETRIEVE_USERS_POSTS_FAIL";

export const REQUEST_RETRIEVE_POSTS = "REQUEST_RETRIEVE_POSTS";
export const RETRIEVE_POSTS_SUCCESS = "RETRIEVE_POSTS_SUCCESS";
export const RETRIEVE_POSTS_FAIL = "RETRIEVE_POSTS_FAIL";

export const REQUEST_RETRIEVE_LIKES_POSTS = "REQUEST_RETRIEVE_LIKES_POSTS";
export const RETRIEVE_LIKES_POSTS_SUCCESS = "RETRIEVE_LIKES_POSTS_SUCCESS";
export const RETRIEVE_LIKES_POSTS_FAIL = "RETRIEVE_LIKES_POSTS_FAIL";

export const REQUEST_RETRIEVE_COMMENTS_POSTS = "REQUEST_RETRIEVE_COMMENTS_POSTS";
export const RETRIEVE_COMMENTS_POSTS_SUCCESS = "RETRIEVE_COMMENTS_POSTS_SUCCESS";
export const RETRIEVE_COMMENTS_POSTS_FAIL = "RETRIEVE_COMMENTS_POSTS_FAIL";

export const REQUEST_RETRIEVE_LIKES_COMMENTS_POSTS = "REQUEST_RETRIEVE_LIKES_COMMENTS_POSTS";
export const RETRIEVE_LIKES_COMMENTS_POSTS_SUCCESS = "RETRIEVE_LIKES_COMMENTS_POSTS_SUCCESS";
export const RETRIEVE_LIKES_COMMENTS_POSTS_FAIL = "RETRIEVE_LIKES_COMMENTS_POSTS_FAIL";

export const REQUEST_RETRIEVE_LIKES_REPLY_COMMENTS_POSTS = "REQUEST_RETRIEVE_LIKES_REPLY_COMMENTS_POSTS";
export const RETRIEVE_LIKES_REPLY_COMMENTS_POSTS_SUCCESS = "RETRIEVE_LIKES_REPLY_COMMENTS_POSTS_SUCCESS";
export const RETRIEVE_LIKES_REPLY_COMMENTS_POSTS_FAIL = "RETRIEVE_LIKES_REPLY_COMMENTS_POSTS_FAIL";

export const REQUEST_CREATE_POSTS_TEKS = "REQUEST_CREATE_POSTS_TEKS";
export const CREATE_POSTS_TEKS_SUCCESS = "CREATE_POSTS_TEKS_SUCCESS";
export const CREATE_POSTS_TEKS_FAIL = "CREATE_POSTS_TEKS_FAIL";

export const REQUEST_CREATE_POSTS_BACKGROUND = "REQUEST_CREATE_POSTS_BACKGROUND";
export const CREATE_POSTS_BACKGROUND_SUCCESS = "CREATE_POSTS_BACKGROUND_SUCCESS";
export const CREATE_POSTS_BACKGROUND_FAIL = "CREATE_POSTS_BACKGROUND_FAIL";

export const REQUEST_CREATE_POSTS_IMAGES = "REQUEST_CREATE_POSTS_IMAGES";
export const CREATE_POSTS_IMAGES_SUCCESS = "CREATE_POSTS_IMAGES_SUCCESS";
export const CREATE_POSTS_IMAGES_FAIL = "CREATE_POSTS_IMAGES_FAIL";

export const REQUEST_CREATE_POSTS_VIDEOS = "REQUEST_CREATE_POSTS_VIDEOS";
export const CREATE_POSTS_VIDEOS_SUCCESS = "CREATE_POSTS_VIDEOS_SUCCESS";
export const CREATE_POSTS_VIDEOS_FAIL = "CREATE_POSTS_VIDEOS_FAIL";

export const REQUEST_CREATE_POSTS_LINK = "REQUEST_CREATE_POSTS_LINK";
export const CREATE_POSTS_LINK_SUCCESS = "CREATE_POSTS_LINK_SUCCESS";
export const CREATE_POSTS_LINK_FAIL = "CREATE_POSTS_LINK_FAIL";

export const REQUEST_CREATE_POSTS_TVS = "REQUEST_CREATE_POSTS_TVS";
export const CREATE_POSTS_TVS_SUCCESS = "CREATE_POSTS_TVS_SUCCESS";
export const CREATE_POSTS_TVS_FAIL = "CREATE_POSTS_TVS_FAIL";

export const REQUEST_CREATE_LIKES_POSTS = "REQUEST_CREATE_LIKES_POSTS";
export const CREATE_LIKES_POSTS_SUCCESS = "CREATE_LIKES_POSTS_SUCCESS";
export const CREATE_LIKES_POSTS_FAIL = "CREATE_LIKES_POSTS_FAIL";

export const REQUEST_CREATE_COMMENTS_POSTS_TEKS = "REQUEST_CREATE_COMMENTS_POSTS_TEKS";
export const CREATE_COMMENTS_POSTS_TEKS_SUCCESS = "CREATE_COMMENTS_POSTS_TEKS_SUCCESS";
export const CREATE_COMMENTS_POSTS_TEKS_FAIL = "CREATE_COMMENTS_POSTS_TEKS_FAIL";

export const REQUEST_CREATE_COMMENTS_POSTS_STICKER = "REQUEST_CREATE_COMMENTS_POSTS_STICKER";
export const CREATE_COMMENTS_POSTS_STICKER_SUCCESS = "CREATE_COMMENTS_POSTS_STICKER_SUCCESS";
export const CREATE_COMMENTS_POSTS_STICKER_FAIL = "CREATE_COMMENTS_POSTS_STICKER_FAIL";

export const REQUEST_CREATE_REPLY_COMMENTS_POSTS_TEKS = "REQUEST_CREATE_REPLY_COMMENTS_POSTS_TEKS";
export const CREATE_REPLY_COMMENTS_POSTS_TEKS_SUCCESS = "CREATE_REPLY_COMMENTS_POSTS_TEKS_SUCCESS";
export const CREATE_REPLY_COMMENTS_POSTS_TEKS_FAIL = "CREATE_REPLY_COMMENTS_POSTS_TEKS_FAIL";

export const REQUEST_CREATE_REPLY_COMMENTS_POSTS_STICKER = "REQUEST_CREATE_REPLY_COMMENTS_POSTS_STICKER";
export const CREATE_REPLY_COMMENTS_POSTS_STICKER_SUCCESS = "CREATE_REPLY_COMMENTS_POSTS_STICKER_SUCCESS";
export const CREATE_REPLY_COMMENTS_POSTS_STICKER_FAIL = "CREATE_REPLY_COMMENTS_POSTS_STICKER_FAIL";

export const REQUEST_CREATE_LIKES_COMMENTS_POSTS = "REQUEST_CREATE_LIKES_COMMENTS_POSTS";
export const CREATE_LIKES_COMMENTS_POSTS_SUCCESS = "CREATE_LIKES_COMMENTS_POSTS_SUCCESS";
export const CREATE_LIKES_COMMENTS_POSTS_FAIL = "CREATE_LIKES_COMMENTS_POSTS_FAIL";

export const REQUEST_CREATE_LIKES_REPLY_COMMENTS_POSTS = "REQUEST_CREATE_LIKES_REPLY_COMMENTS_POSTS";
export const CREATE_LIKES_REPLY_COMMENTS_POSTS_SUCCESS = "CREATE_LIKES_REPLY_COMMENTS_POSTS_SUCCESS";
export const CREATE_LIKES_REPLY_COMMENTS_POSTS_FAIL = "CREATE_LIKES_REPLY_COMMENTS_POSTS_FAIL";

export const REQUEST_CREATE_REPORT_POSTS = "REQUEST_CREATE_REPORT_POSTS";
export const CREATE_REPORT_POSTS_SUCCESS = "CREATE_REPORT_POSTS_SUCCESS";
export const CREATE_REPORT_POSTS_FAIL = "CREATE_REPORT_POSTS_FAIL";

export const REQUEST_CREATE_SAVE_POSTS = "REQUEST_CREATE_SAVE_POSTS";
export const CREATE_SAVE_POSTS_SUCCESS = "CREATE_SAVE_POSTS_SUCCESS";
export const CREATE_SAVE_POSTS_FAIL = "CREATE_SAVE_POSTS_FAIL";

export const REQUEST_UPDATE_POSTS_TEKS = "REQUEST_UPDATE_POSTS_TEKS";
export const UPDATE_POSTS_TEKS_SUCCESS = "UPDATE_POSTS_TEKS_SUCCESS";
export const UPDATE_POSTS_TEKS_FAIL = "UPDATE_POSTS_TEKS_FAIL";

export const REQUEST_UPDATE_POSTS_BACKGROUND = "REQUEST_UPDATE_POSTS_BACKGROUND";
export const UPDATE_POSTS_BACKGROUND_SUCCESS = "UPDATE_POSTS_BACKGROUND_SUCCESS";
export const UPDATE_POSTS_BACKGROUND_FAIL = "UPDATE_POSTS_BACKGROUND_FAIL";

export const REQUEST_UPDATE_POSTS_IMAGES = "REQUEST_UPDATE_POSTS_IMAGES";
export const UPDATE_POSTS_IMAGES_SUCCESS = "UPDATE_POSTS_IMAGES_SUCCESS";
export const UPDATE_POSTS_IMAGES_FAIL = "UPDATE_POSTS_IMAGES_FAIL";

export const REQUEST_UPDATE_POSTS_VIDEOS = "REQUEST_UPDATE_POSTS_VIDEOS";
export const UPDATE_POSTS_VIDEOS_SUCCESS = "UPDATE_POSTS_VIDEOS_SUCCESS";
export const UPDATE_POSTS_VIDEOS_FAIL = "UPDATE_POSTS_VIDEOS_FAIL";

export const REQUEST_UPDATE_POSTS_LINK = "REQUEST_UPDATE_POSTS_LINK";
export const UPDATE_POSTS_LINK_SUCCESS = "UPDATE_POSTS_LINK_SUCCESS";
export const UPDATE_POSTS_LINK_FAIL = "UPDATE_POSTS_LINK_FAIL";

export const REQUEST_UPDATE_POSTS_TVS = "REQUEST_UPDATE_POSTS_TVS";
export const UPDATE_POSTS_TVS_SUCCESS = "UPDATE_POSTS_TVS_SUCCESS";
export const UPDATE_POSTS_TVS_FAIL = "UPDATE_POSTS_TVS_FAIL";

export const REQUEST_DELETE_POSTS = "REQUEST_DELETE_POSTS";
export const DELETE_POSTS_SUCCESS = "DELETE_POSTS_SUCCESS";
export const DELETE_POSTS_FAIL = "DELETE_POSTS_FAIL";

export const REQUEST_DELETE_COMMENTS_POSTS = "REQUEST_DELETE_COMMENTS_POSTS";
export const DELETE_COMMENTS_POSTS_SUCCESS = "DELETE_COMMENTS_POSTS_SUCCESS";
export const DELETE_COMMENTS_POSTS_FAIL = "DELETE_COMMENTS_POSTS_FAIL";

export const REQUEST_DELETE_REPLY_COMMENTS_POSTS = "REQUEST_DELETE_REPLY_COMMENTS_POSTS";
export const DELETE_REPLY_COMMENTS_POSTS_SUCCESS = "DELETE_REPLY_COMMENTS_POSTS_SUCCESS";
export const DELETE_REPLY_COMMENTS_POSTS_FAIL = "DELETE_REPLY_COMMENTS_POSTS_FAIL";
