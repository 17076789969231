import {
    REQUEST_RETRIEVE_ALL_PRODUCTS,
    RETRIEVE_ALL_PRODUCTS_SUCCESS,
    RETRIEVE_ALL_PRODUCTS_FAIL,
    REQUEST_RETRIEVE_USERS_PRODUCTS,
    RETRIEVE_USERS_PRODUCTS_SUCCESS,
    RETRIEVE_USERS_PRODUCTS_FAIL,
    REQUEST_RETRIEVE_PRODUCTS,
    RETRIEVE_PRODUCTS_SUCCESS,
    RETRIEVE_PRODUCTS_FAIL,
    REQUEST_CREATE_PRODUCTS,
    CREATE_PRODUCTS_SUCCESS,
    CREATE_PRODUCTS_FAIL,
    REQUEST_UPDATE_PRODUCTS,
    UPDATE_PRODUCTS_SUCCESS,
    UPDATE_PRODUCTS_FAIL,
    REQUEST_DELETE_PRODUCTS,
    DELETE_PRODUCTS_SUCCESS,
    DELETE_PRODUCTS_FAIL,
    REQUEST_RETRIEVE_LIKES_PRODUCTS,
    RETRIEVE_LIKES_PRODUCTS_SUCCESS,
    RETRIEVE_LIKES_PRODUCTS_FAIL,
    REQUEST_RETRIEVE_REVIEWS_PRODUCTS,
    RETRIEVE_REVIEWS_PRODUCTS_SUCCESS,
    RETRIEVE_REVIEWS_PRODUCTS_FAIL,
    REQUEST_RETRIEVE_LIKES_REVIEWS_PRODUCTS,
    RETRIEVE_LIKES_REVIEWS_PRODUCTS_SUCCESS,
    RETRIEVE_LIKES_REVIEWS_PRODUCTS_FAIL,
    REQUEST_RETRIEVE_LIKES_REPLY_REVIEWS_PRODUCTS,
    RETRIEVE_LIKES_REPLY_REVIEWS_PRODUCTS_SUCCESS,
    RETRIEVE_LIKES_REPLY_REVIEWS_PRODUCTS_FAIL,
    REQUEST_CREATE_REVIEWS_PRODUCTS_TEKS,
    CREATE_REVIEWS_PRODUCTS_TEKS_SUCCESS,
    CREATE_REVIEWS_PRODUCTS_TEKS_FAIL,
    REQUEST_CREATE_REVIEWS_PRODUCTS_IMAGES,
    CREATE_REVIEWS_PRODUCTS_IMAGES_SUCCESS,
    CREATE_REVIEWS_PRODUCTS_IMAGES_FAIL,
    REQUEST_CREATE_REPLY_REVIEWS_PRODUCTS_TEKS,
    CREATE_REPLY_REVIEWS_PRODUCTS_TEKS_SUCCESS,
    CREATE_REPLY_REVIEWS_PRODUCTS_TEKS_FAIL,
    REQUEST_CREATE_REPLY_REVIEWS_PRODUCTS_IMAGES,
    CREATE_REPLY_REVIEWS_PRODUCTS_IMAGES_SUCCESS,
    CREATE_REPLY_REVIEWS_PRODUCTS_IMAGES_FAIL,
    //REQUEST_CREATE_LIKES_PRODUCTS,
    CREATE_LIKES_PRODUCTS_SUCCESS,
    CREATE_LIKES_PRODUCTS_FAIL,
    REQUEST_CREATE_LIKES_REVIEWS_PRODUCTS,
    CREATE_LIKES_REVIEWS_PRODUCTS_SUCCESS,
    CREATE_LIKES_REVIEWS_PRODUCTS_FAIL,
    REQUEST_CREATE_LIKES_REPLY_REVIEWS_PRODUCTS,
    CREATE_LIKES_REPLY_REVIEWS_PRODUCTS_SUCCESS,
    CREATE_LIKES_REPLY_REVIEWS_PRODUCTS_FAIL,
    REQUEST_CREATE_REPORT_PRODUCTS,
    CREATE_REPORT_PRODUCTS_SUCCESS,
    CREATE_REPORT_PRODUCTS_FAIL,
    REQUEST_DELETE_REVIEWS_PRODUCTS,
    DELETE_REVIEWS_PRODUCTS_SUCCESS,
    DELETE_REVIEWS_PRODUCTS_FAIL,
    REQUEST_DELETE_REPLY_REVIEWS_PRODUCTS,
    DELETE_REPLY_REVIEWS_PRODUCTS_SUCCESS,
    DELETE_REPLY_REVIEWS_PRODUCTS_FAIL,
} from "../../../actions/products/TypesProducts";

const initialState = {
    listProducts: [],
    listLikesProducts: [],
    listReviewsProducts: [],
    listLikesReviewsProducts: [],
    listLikesReplyReviewsProducts: [],
    detailsProduct: null,
    isLoading: false,
};

function productsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case REQUEST_RETRIEVE_ALL_PRODUCTS:
            return {
                ...state,
                isLoading: true,
            }
        case RETRIEVE_ALL_PRODUCTS_SUCCESS:
            return {
                ...state,
                listProducts: payload,
                isLoading: false,
            }
        case RETRIEVE_ALL_PRODUCTS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case REQUEST_RETRIEVE_USERS_PRODUCTS:
            return {
                ...state,
                isLoading: true,
            }
        case RETRIEVE_USERS_PRODUCTS_SUCCESS:
            return {
                ...state,
                listProducts: payload,
                isLoading: false,
            }
        case RETRIEVE_USERS_PRODUCTS_FAIL:
            return {
                ...state,
                isLoading: false
            }
        case REQUEST_RETRIEVE_PRODUCTS:
            return {
                ...state,
                isLoading: true,
            }
        case RETRIEVE_PRODUCTS_SUCCESS:
            return {
                ...state,
                detailsProduct: payload,
                isLoading: false,
            }
        case RETRIEVE_PRODUCTS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        // case REQUEST_CREATE_PRODUCTS:
        //     return {
        //         ...state
        //     }
        case CREATE_PRODUCTS_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_PRODUCTS_FAIL:
            return {
                ...state,
            }
        case REQUEST_UPDATE_PRODUCTS:
            return {
                ...state,
            }
        case UPDATE_PRODUCTS_SUCCESS:
            return state.map((product) => {
                if (product.id === payload.id) {
                    return {
                        ...product,
                        ...payload,
                    }
                } else {
                    return product;
                }
            });
        case UPDATE_PRODUCTS_FAIL:
            return {
                ...state,
            }
        case REQUEST_DELETE_PRODUCTS:
            return {
                ...state
            }
        case DELETE_PRODUCTS_SUCCESS:
            return state.filter(({ id }) => id !== payload.id);
        case DELETE_PRODUCTS_FAIL:
            return {
                ...state,
            }
        case REQUEST_RETRIEVE_LIKES_PRODUCTS:
            return {
                ...state,
                isLoading: true,
            }
        case RETRIEVE_LIKES_PRODUCTS_SUCCESS:
            return {
                ...state,
                listLikesProducts: payload,
                isLoading: false,
            }
        case RETRIEVE_LIKES_PRODUCTS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case REQUEST_RETRIEVE_REVIEWS_PRODUCTS:
            return {
                ...state,
                isLoading: true,
            }
        case RETRIEVE_REVIEWS_PRODUCTS_SUCCESS:
            return {
                ...state,
                listReviewsProducts: payload,
                isLoading: false
            }
        case RETRIEVE_REVIEWS_PRODUCTS_FAIL:
            return {
                ...state,
                isLoading: false
            }
        case REQUEST_RETRIEVE_LIKES_REVIEWS_PRODUCTS:
            return {
                ...state,
                isLoading: true,
            }
        case RETRIEVE_LIKES_REVIEWS_PRODUCTS_SUCCESS:
            return {
                ...state,
                listLikesReviewsProducts: payload,
                isLoading: false,
            }
        case RETRIEVE_LIKES_REVIEWS_PRODUCTS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case REQUEST_RETRIEVE_LIKES_REPLY_REVIEWS_PRODUCTS:
            return {
                ...state,
                isLoading: true,
            }
        case RETRIEVE_LIKES_REPLY_REVIEWS_PRODUCTS_SUCCESS:
            return {
                ...state,
                listLikesReplyReviewsProducts: payload,
                isLoading: false
            }
        case RETRIEVE_LIKES_REPLY_REVIEWS_PRODUCTS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case REQUEST_CREATE_REVIEWS_PRODUCTS_TEKS:
            return {
                ...state,
            }
        case CREATE_REVIEWS_PRODUCTS_TEKS_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_REVIEWS_PRODUCTS_TEKS_FAIL:
            return {
                ...state,
            }
        case REQUEST_CREATE_REVIEWS_PRODUCTS_IMAGES:
            return {
                ...state
            }
        case CREATE_REVIEWS_PRODUCTS_IMAGES_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_REVIEWS_PRODUCTS_IMAGES_FAIL:
            return {
                ...state
            }
        case REQUEST_CREATE_REPLY_REVIEWS_PRODUCTS_TEKS:
            return {
                ...state,
            }
        case CREATE_REPLY_REVIEWS_PRODUCTS_TEKS_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_REPLY_REVIEWS_PRODUCTS_TEKS_FAIL:
            return {
                ...state
            }
        case REQUEST_CREATE_REPLY_REVIEWS_PRODUCTS_IMAGES:
            return {
                ...state
            }
        case CREATE_REPLY_REVIEWS_PRODUCTS_IMAGES_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_REPLY_REVIEWS_PRODUCTS_IMAGES_FAIL:
            return {
                ...state
            }
        // case REQUEST_CREATE_LIKES_PRODUCTS:
        //     return {
        //         ...state
        //     }
        case CREATE_LIKES_PRODUCTS_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_LIKES_PRODUCTS_FAIL:
            return {
                ...state
            }
        case REQUEST_CREATE_LIKES_REVIEWS_PRODUCTS:
            return {
                ...state
            }
        case CREATE_LIKES_REVIEWS_PRODUCTS_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_LIKES_REVIEWS_PRODUCTS_FAIL:
            return {
                ...state
            }
        case REQUEST_CREATE_LIKES_REPLY_REVIEWS_PRODUCTS:
            return {
                ...state
            }
        case CREATE_LIKES_REPLY_REVIEWS_PRODUCTS_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_LIKES_REPLY_REVIEWS_PRODUCTS_FAIL:
            return {
                ...state
            }
        case REQUEST_CREATE_REPORT_PRODUCTS:
            return {
                ...state
            }
        case CREATE_REPORT_PRODUCTS_SUCCESS:
            return {
                ...state,
                payload
            };
        case CREATE_REPORT_PRODUCTS_FAIL:
            return {
                ...state
            }
        case REQUEST_DELETE_REVIEWS_PRODUCTS:
            return {
                ...state,
            }
        case DELETE_REVIEWS_PRODUCTS_SUCCESS:
            return state.filter(({ id }) => id !== payload.id);
        case DELETE_REVIEWS_PRODUCTS_FAIL:
            return {
                ...state
            }
        case REQUEST_DELETE_REPLY_REVIEWS_PRODUCTS:
            return {
                ...state,
            }
        case DELETE_REPLY_REVIEWS_PRODUCTS_SUCCESS:
            return state.filter(({ id }) => id !== payload.id);
        case DELETE_REPLY_REVIEWS_PRODUCTS_FAIL:
            return {
                ...state,
            }
        default:
            return state;
    }
}

export default productsReducer;