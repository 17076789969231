import {
    REQUEST_RETRIEVE_CURRENT_USER,
    RETRIEVE_CURRENT_USER_SUCCESS,
    RETRIEVE_CURRENT_USER_FAIL,
    REQUEST_RETRIEVE_EXPLORER,
    RETRIEVE_EXPLORER_SUCCESS,
    RETRIEVE_EXPLORER_FAIL,
    REQUEST_RETRIEVE_FORYOU,
    RETRIEVE_FORYOU_SUCCESS,
    RETRIEVE_FORYOU_FAIL,
    REQUEST_RETRIEVE_CONTACTS,
    RETRIEVE_CONTACTS_SUCCESS,
    RETRIEVE_CONTACTS_FAIL,
    REQUEST_RETRIEVE_SUGGESTION_PEOPLE,
    RETRIEVE_SUGGESTION_PEOPLE_SUCCESS,
    RETRIEVE_SUGGESTION_PEOPLE_FAIL,
    REQUEST_PREVIEWS_URL,
    RETRIEVE_PREVIEWS_URL_SUCCESS,
    RETRIEVE_PREVIEWS_URL_FAIL,
    REQUEST_RETRIEVE_MEMORIES,
    RETRIEVE_MEMORIES_SUCCESS,
    RETRIEVE_MEMORIES_FAIL
} from './types';

import RecomendedService from "../../services/recomended_service";
import ExplorerService from "../../services/posts/ExplorerService";
import user_service from '../../services/user_service';

export const retrieveCurrentUser = () => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_CURRENT_USER,
    });
    try {
        const res = await user_service.getAuthenticatedUser();
        dispatch({
            type: RETRIEVE_CURRENT_USER_SUCCESS,
            payload: res.data.data
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_CURRENT_USER_FAIL,
        });
    }
}

export const retrieveExplorer = (page = 1) => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_EXPLORER,
    });
    try {
        const res = await ExplorerService.getExplorer(page);
        dispatch({
            type: RETRIEVE_EXPLORER_SUCCESS,
            payload: {
                data: res.data.data,
                page,
            },
        })
    } catch (err) {
        dispatch({
            type: RETRIEVE_EXPLORER_FAIL,
        });
    }
}

export const retrieveForyou = (page = 1) => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_FORYOU,
    });
    try {
        const res = await RecomendedService.getForYou(page);
        dispatch({
            type: RETRIEVE_FORYOU_SUCCESS,
            payload: {
                data: res.data.data,
                page,
            },
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_FORYOU_FAIL,
        });
    }
}

export const retrieveContacts = () => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_CONTACTS,
    });
    try {
        const res = await RecomendedService.getContacts();
        dispatch({
            type: RETRIEVE_CONTACTS_SUCCESS,
            payload: res.data.data,
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_CONTACTS_FAIL,
        });
    }
}

export const retrieveSuggestionPeople = () => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_SUGGESTION_PEOPLE,
    });
    try {
        const res = await RecomendedService.getSuggestionPeople();
        dispatch({
            type: RETRIEVE_SUGGESTION_PEOPLE_SUCCESS,
            payload: res.data.data,
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_SUGGESTION_PEOPLE_FAIL,
        });
    }
}

export const retrievePreviewsUrl = (data) => async (dispatch) => {
    dispatch({
        type: REQUEST_PREVIEWS_URL,
    });
    try {
        const res = await RecomendedService.previewsUrl(data);
        console.log(res);
        dispatch({
            type: RETRIEVE_PREVIEWS_URL_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_PREVIEWS_URL_FAIL,
        });
    }
}

export const retrieveMemories = () => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_MEMORIES,
    });
    try {
        const res = await RecomendedService.getMemories();
        dispatch({
            type: RETRIEVE_MEMORIES_SUCCESS,
            payload: res.data.data,
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_MEMORIES_FAIL,
        });
    }
}