import React from "react";

function PostsPlaceholder() {
    const rows = Array(8).fill().map((_, i) => (
        <div key={i} className="shadow bg-white dark:bg-dark-second dark:text-dark-txt mt-4 rounded-none lg:rounded-lg xl:rounded-lg">
            <div className="flex items-center justify-between px-4 py-2">
                <div className="flex space-x-2 items-center">
                    <div className="relative">
                        <div className="w-10 h-10 rounded-full bg-gray-300 dark:bg-gray-700"> </div>
                    </div>
                    <div className="space-y-2">
                        <div className="font-normal text-xs h-3 w-20 rounded-md bg-gray-300 dark:bg-gray-700">
                        </div>
                        <div className="text-xs text-gray-500 h-3 w-10 rounded-md bg-gray-300 dark:bg-gray-700">

                        </div>
                    </div>
                </div>

                <div className="w-8 h-8 grid place-items-center text-xl text-gray-500 bg-gray-300 dark:bg-gray-700 hover:bg-gray-200 dark:text-dark-txt dark:hover:bg-dark-third rounded-full cursor-pointer">
                </div>
            </div>
            <div className="py-2">
                <div className="bg-gray-300 dark:bg-gray-700 h-52 w-full"></div>
            </div>
            <div className="p-2">
                <div className="text-justify text-sm bg-gray-300 dark:bg-gray-700 h-4 w-full rounded-md">
                </div>
            </div>
            <div className="pb-0 pt-0 px-4 mt-2">
                <div className="border border-gray-200 dark:border-dark-third border-l-0 border-r-0 border-b-0 border-t-0 py-1">
                    <div className="flex items-center justify-between">
                        <div className="flex">
                            <div className="space-x-2 ml-3 mr-3 justify-center  bg-gray-300 dark:bg-gray-700 items-center text-xl py-2 w-7 h-7 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">

                            </div>
                            <div className="space-x-2 ml-3  bg-gray-300 dark:bg-gray-700 mr-3 justify-center items-center text-xl py-2 w-7 h-7 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">

                            </div>
                            <div className="space-x-2 ml-3 mr-3 justify-center  bg-gray-300 dark:bg-gray-700 items-center text-xl py-2 w-7 h-7 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">

                            </div>
                        </div>
                        <div className="flex">
                            <div className="space-x-2 mr-3 justify-center  bg-gray-300 dark:bg-gray-700 items-center w-7 h-7 text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">

                            </div>
                            <div className="space-x-2 ml-3 mr-3 justify-center  bg-gray-300 dark:bg-gray-700 items-center w-7 h-7 text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">

                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div className="px-4 pb-4 mt-4">
                <div className="flex items-center justify-start space-x-3">
                    <div className=" bg-gray-300 dark:bg-gray-700 h-4 w-20 rounded-lg dark:text-dark-txt">

                    </div>
                    <div className=" bg-gray-300 dark:bg-gray-700 h-4 w-20 rounded-lg dark:text-dark-txt">

                    </div>
                </div>
            </div>
        </div>
    ));
    return (
        <div className=" animate-pulse">
            {rows}
        </div>
    );
}

export default PostsPlaceholder;