import React, { Component, useEffect } from 'react';
import { faImages, faVideo, faTv, faBook, faLink, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { retrieveCurrentUser } from '../../../actions/home/home';
export default function FormAddPostsWidget() {
  const { isLoading, currentUser } = useSelector(state => state.home);
  const dispattch = useDispatch();

  useEffect(() => {
    dispattch(retrieveCurrentUser());
  }, [dispattch]);

  return (
    <div className="px-4 mt-4 shadow rounded-none lg:rounded-lg xl:rounded-lg bg-white dark:bg-dark-second">
      <div className="p-2 border-b border-gray-300 dark:border-dark-third flex space-x-4">
        {
          currentUser && currentUser.covers ? (
            <img src={currentUser.covers.files_small_thumbnail} alt="Profile picture" className="w-10 h-10 rounded-full" />
          ) : (
            <div className='bg-gray-300 dark:bg-gray-600 w-10 h-10 rounded-full'
            ></div>
          )
        }

        <Link to="/posts/create/posts-teks" className="flex-1 bg-gray-100 rounded-full flex items-center justify-start pl-4 cursor-pointer dark:bg-dark-third text-gray-500 text-lg dark:text-dark-txt">
          <span className='text-sm'>
            Write something here ...
          </span>
        </Link>
      </div>
      <div className="p-2 flex">
        <Link to="/posts/create/posts-images" data-tooltip-target="tooltip-photos" data-tooltip-trigger="hover" data-tooltip-placement="bottom" className="w-1/3 flex space-x-2 justify-center items-center hover:bg-gray-100 dark:hover:bg-dark-third text-xl sm:text-3xl py-2 rounded-lg cursor-pointer text-green-500">
          <FontAwesomeIcon icon={faImages} className="text-gray-400 text-xl" />
        </Link>
        <div id="tooltip-photos" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
          Photos
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
        <Link to="/posts/create/posts-videos" data-tooltip-target="tooltip-video" data-tooltip-trigger="hover" data-tooltip-placement="bottom" className="w-1/3 flex space-x-2 justify-center items-center hover:bg-gray-100 dark:hover:bg-dark-third text-xl sm:text-3xl py-2 rounded-lg cursor-pointer text-red-500">
          <FontAwesomeIcon icon={faVideo} className="text-gray-400 text-xl" />
        </Link>
        <div id="tooltip-video" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
          Video
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
        <Link to="/posts/create/posts-tvs" data-tooltip-target="tooltip-tvs" data-tooltip-trigger="hover" data-tooltip-placement="bottom" className="w-1/3 flex space-x-2 justify-center items-center hover:bg-gray-100 dark:hover:bg-dark-third text-xl sm:text-3xl py-2 rounded-lg cursor-pointer text-red-500">
          <FontAwesomeIcon icon={faTv} className="text-gray-400 text-xl" />
        </Link>
        <div id="tooltip-tvs" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
          TVS
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
        <Link to="/posts/create/posts-background" data-tooltip-target="tooltip-background" data-tooltip-trigger="hover" data-tooltip-placement="bottom" className="w-1/3 flex space-x-2 justify-center items-center hover:bg-gray-100 dark:hover:bg-dark-third text-xl sm:text-3xl py-2 rounded-lg cursor-pointer text-red-500">
          <FontAwesomeIcon icon={faBook} className="text-gray-400 text-xl" />
        </Link>
        <div id="tooltip-background" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
          Background
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
        <Link to="/posts/create/posts-link" data-tooltip-target="tooltip-link" data-tooltip-trigger="hover" data-tooltip-placement="bottom" className="w-1/3 flex space-x-2 justify-center items-center hover:bg-gray-100 dark:hover:bg-dark-third text-xl sm:text-3xl py-2 rounded-lg cursor-pointer text-red-500">
          <FontAwesomeIcon icon={faLink} className="text-gray-400 text-xl" />
        </Link>
        <div id="tooltip-link" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
          Link
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
      </div>
    </div>
  );
}
