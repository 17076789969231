import {
    REQUEST_RETRIEVE_ALL_NOTES,
    RETRIEVE_ALL_NOTES_SUCCESS,
    RETRIEVE_ALL_NOTES_FAIL,
    REQUEST_RETRIEVE_NOTES,
    RETRIEVE_NOTES_SUCCESS,
    RETRIEVE_NOTES_FAIL,
    REQUEST_CREATE_NOTES,
    CREATE_NOTES_SUCCESS,
    CREATE_NOTES_FAIL,
    REQUEST_UPDATE_NOTES,
    UPDATE_NOTES_SUCCESS,
    UPDATE_NOTES_FAIL,
    REQUEST_DELETE_NOTES,
    DELETE_NOTES_SUCCESS,
    DELETE_NOTES_FAIL,
} from "../../../actions/notes/TypesNotes";

const initialState = {
    listNotes: [],
    detailsNote: null,
    isLoading: false,
};

function notesReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case REQUEST_RETRIEVE_ALL_NOTES:
            return {
                ...state,
                isLoading: true,
            }
        case RETRIEVE_ALL_NOTES_SUCCESS:
            return {
                ...state,
                listNotes: payload,
                isLoading: false,
            }
        case RETRIEVE_ALL_NOTES_FAIL:
            return {
                ...state,
                isLoading: false
            }
        case REQUEST_RETRIEVE_NOTES:
            return {
                ...state,
                isLoading: true,
            }
        case RETRIEVE_NOTES_SUCCESS:
            return {
                ...state,
                detailsNote: payload,
                isLoading: false,
            }
        case RETRIEVE_NOTES_FAIL:
            return {
                ...state,
                isLoading: false
            }
        case REQUEST_CREATE_NOTES:
            return {
                ...state
            }
        case CREATE_NOTES_SUCCESS:
            return {
                ...state,
                payload
            };
        case CREATE_NOTES_FAIL:
            return {
                ...state,
            }
        case REQUEST_UPDATE_NOTES:
            return {
                ...state,
            }
        case UPDATE_NOTES_SUCCESS:
            return state.map((note) => {
                if (note.id === payload.id) {
                    return {
                        ...note,
                        ...payload,
                    }
                } else {
                    return note;
                }
            });
        case UPDATE_NOTES_FAIL:
            return {
                ...state,
            }
        case REQUEST_DELETE_NOTES:
            return {
                ...state,
            }
        case DELETE_NOTES_SUCCESS:
            return state.filter(({ id }) => id !== payload.id);
        case DELETE_NOTES_FAIL:
            return {
                ...state,
            }
        default:
            return state;
    }
}
export default notesReducer;