import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Contact1 from '../../../assets/images/new/avt-3.jpg';
import { Link } from 'react-router-dom';
import { faHeart, faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class CommentsMomentsWidget extends Component {
    render() {
        const { t } = this.props
        return (
            <div className="flex space-x-2 mb-2">
                <img src={Contact1} alt="Profile picture" className="w-9 h-9 rounded-full" />
                <div>
                    <div className="bg-gray-100 dark:bg-dark-third p-2 rounded-2xl text-sm">
                        <Link to="/profile" className="font-semibold block text-black dark:text-dark-txt">John Doe</Link>
                        <span className="text-black dark:text-dark-txt">Lorem ipsum dolor sit amet consectetur, adipisicing elit.In voluptate ipsa animi corrupti unde, voluptatibus expedita suscipit, itaque, laudantium accusantium aspernatur officia repellendus nihil mollitia soluta distinctio praesentium nulla eos?</span>
                    </div>
                    <div className="p-2 text-xs text-gray-500 dark:text-dark-txt">
                        <span className="font-semibold cursor-pointer">2 Likes</span>
                        <span className="font-semibold cursor-pointer ml-2">3 Reply</span>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex space-x-3">
                            <div className="space-x-2 ml-3 mr-3 justify-center items-center text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">
                                <FontAwesomeIcon icon={faHeart} className="text-red-400 text-xl" />
                            </div>
                            <div className="space-x-2 ml-3 mr-3 justify-center items-center text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">
                                <FontAwesomeIcon icon={faComment} className="text-gray-400 text-xl" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(CommentsMomentsWidget)