import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit, faInfoCircle, faTrashAlt, faLink, faShareAlt, faFlag } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveCurrentUser } from '../../../actions/home/home';
import ShareComponent from '../util/ShareComponent';
import moment from 'moment';
import 'moment/locale/id';
import { useLocation } from 'react-router-dom';

const MenuPostComponent = ({ post, isMenuOpen, toggleMenu, onDetail, onEdit, onDelete }) => {
    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const { isLoading, currentUser } = useSelector(state => state.home);
    const [isShareDialogOpen, setShareDialogOpen] = useState(false);

    const now = moment().utc().startOf('day');
    const createdAt = moment(post.created_at).utc().startOf('day');

    const isOlderThanAYear = now.diff(createdAt, 'years') >= 1;
    const formattedDate = isOlderThanAYear ? createdAt.format('D MMMM YYYY') : createdAt.fromNow();

    const message = encodeURIComponent("Shared from social network v2");
    const shareUrl = `https://m.trafalines.com/post/${post.id}?source=app&message=${message}`;
    const shareTitle = `Check out this post by ${post.users.username} on Trafalines Social Network V2, posted on ${formattedDate}:`;

    const toggleShareDialog = () => {
        setShareDialogOpen(!isShareDialogOpen);

    };

    const location = useLocation();
    const currentPath = location.pathname;
    const isCurrentPostPath = currentPath === `/post/${post.id}`;

    useEffect(() => {
        if (currentUser === null) {
            dispatch(retrieveCurrentUser());
        }

        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                if (!isShareDialogOpen) {
                    toggleMenu();
                }
            }
        };

        if (isMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen, toggleMenu, currentUser, dispatch, isShareDialogOpen]);

    if (!isMenuOpen) return null;

    const canEditOrDelete = currentUser && currentUser.id === post.users.id;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shareUrl);
        alert('Link copied to clipboard!');
    };

    return (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center p-4">
            <div
                ref={modalRef}
                role="dialog"
                aria-labelledby="menu-dialog-title"
                aria-modal="true"
                className="w-full max-w-md bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 shadow-lg rounded-lg p-4 animate-fade-in"
            >
                <div className="flex justify-between items-center mb-4">
                    <h3 id="menu-dialog-title" className="text-lg font-semibold text-gray-900 dark:text-white">Post Menu</h3>
                    <button
                        onClick={toggleMenu}
                        className="text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
                    >
                        <FontAwesomeIcon icon={faTimes} className="w-4 h-4" />
                    </button>
                </div>

                {isLoading ? (
                    <div className="flex justify-center items-center h-32">
                        <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
                    </div>
                ) : (
                    <ul className="space-y-2">
                        {!isCurrentPostPath && (
                            <li>
                                <a
                                    href="#"
                                    onClick={(e) => { e.preventDefault(); onDetail(); }}
                                    className="flex items-center gap-4 p-2 text-gray-900 dark:text-white rounded-md hover:bg-gray-200 dark:hover:bg-gray-700"
                                >
                                    <div className='w-6 h-6 flex justify-center items-center'>
                                        <FontAwesomeIcon icon={faInfoCircle} className="w-5 h-5" />
                                    </div>
                                    <span>Detail Post</span>
                                </a>
                            </li>
                        )}
                        {canEditOrDelete && (
                            <>
                                <li>
                                    <a
                                        href="#"
                                        onClick={(e) => { e.preventDefault(); onEdit(); }}
                                        className="flex items-center gap-4 p-2 text-gray-900 dark:text-white rounded-md hover:bg-gray-200 dark:hover:bg-gray-700"
                                    >
                                        <div className='w-6 h-6 flex justify-center items-center'>
                                            <FontAwesomeIcon icon={faEdit} className="w-5 h-5" />
                                        </div>
                                        <span>Edit Post</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        onClick={(e) => { e.preventDefault(); onDelete(); }}
                                        className="flex items-center gap-4 p-2 text-gray-900 dark:text-white rounded-md hover:bg-gray-200 dark:hover:bg-gray-700"
                                    >
                                        <div className='w-6 h-6 flex justify-center items-center'>
                                            <FontAwesomeIcon icon={faTrashAlt} className="w-5 h-5" />
                                        </div>
                                        <span>Delete Post</span>
                                    </a>
                                </li>
                            </>
                        )}
                        <li>
                            <a
                                href="#"
                                onClick={(e) => { e.preventDefault(); toggleShareDialog(); }}
                                className="flex items-center gap-4 p-2 text-gray-900 dark:text-white rounded-md hover:bg-gray-200 dark:hover:bg-gray-700"
                            >
                                <div className='w-6 h-6 flex justify-center items-center'>
                                    <FontAwesomeIcon icon={faShareAlt} className="w-5 h-5" />
                                </div>
                                <span>Share Post</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                onClick={(e) => { e.preventDefault(); /* handle report */ }}
                                className="flex items-center gap-4 p-2 text-gray-900 dark:text-white rounded-md hover:bg-gray-200 dark:hover:bg-gray-700"
                            >
                                <div className='w-6 h-6 flex justify-center items-center'>
                                    <FontAwesomeIcon icon={faFlag} className="w-5 h-5" />
                                </div>
                                <span>Report Post</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                onClick={(e) => { e.preventDefault(); toggleMenu(); }}
                                className="flex items-center gap-4 p-2 text-gray-900 dark:text-white rounded-md hover:bg-gray-200 dark:hover:bg-gray-700"
                            >
                                <div className='w-6 h-6 flex justify-center items-center'>
                                    <FontAwesomeIcon icon={faLink} className="w-5 h-5" />
                                </div>
                                <span>Cancel</span>
                            </a>
                        </li>
                    </ul>
                )}
            </div>
            <ShareComponent
                isShareDialogOpen={isShareDialogOpen}
                toggleShareDialog={toggleShareDialog}
                shareUrl={shareUrl}
                shareTitle={shareTitle}
                copyToClipboard={copyToClipboard}
            />
        </div>
    );
};

export default MenuPostComponent;
