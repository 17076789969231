import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Contact1 from '../../../assets/images/new/post2.jpg';

class SuggestionWodget extends Component {
    render() {
        const { t } = this.props
        return (
            <Link to="/profile" className="m-2 w-auto bg-white rounded-lg border border-gray-200 shadow-md dark:bg-dark-second dark:border-gray-700">
                <div className="flex flex-col items-center pb-1">
                    <div className="relative">
                        <img src={Contact1} class="w-screen h-52 lg:h-44 xl:h-44 object-cover rounded-tl rounded-tr" />
                        <div className=' absolute right-1 top-1'>
                            <div className="mr-1 text-xl bg-gray-100  dark:bg-dark-third text-red-600 grid place-items-center rounded-full p-3 cursor-pointer relative">
                                <FontAwesomeIcon icon={faHeart} />
                            </div>
                        </div>
                    </div>
                    <div className="mt-2 text-xs text-center font-normal text-gray-400 dark:text-white ">Bonnie Green</div>

                    <div className="flex ml-1 mr-1 space-x-2 mt-2 w-full px-1">
                        <Link to="/" className="w-full d-flex items-center py-2 px-4 text-xs font-medium text-center text-white bg-green-400 dark:bg-dark-main rounded-xl hover:bg-green-500 focus:ring-4 focus:ring-blue-300 dark:hover:bg-dark-main dark:focus:ring-blue-800">{t('label_follow')}</Link>
                    </div>
                </div>
            </Link>
        );
    }
}

export default withTranslation()(SuggestionWodget)