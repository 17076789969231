export const REQUEST_RETRIEVE_ALL_STORIES = "REQUEST_RETRIEVE_ALL_STORIES";
export const RETRIEVE_ALL_STORIES_SUCCESS = "RETRIEVE_ALL_STORIES_SUCCESS";
export const RETRIEVE_ALL_STORIES_FAIL = "RETRIEVE_ALL_STORIES_FAIL";

export const REQUEST_RETRIEVE_AUDIENCE_STORIES = "REQUEST_RETRIEVE_AUDIENCE_STORIES";
export const RETRIEVE_AUDIENCE_STORIES_SUCCESS = "RETRIEVE_AUDIENCE_STORIES_SUCCESS";
export const RETRIEVE_AUDIENCE_STORIES_FAIL = "RETRIEVE_AUDIENCE_STORIES_FAIL";

export const REQUEST_RETRIEVE_USERS_STORIES = "REQUEST_RETRIEVE_USERS_STORIES";
export const RETRIEVE_USERS_STORIES_SUCCESS = "RETRIEVE_USERS_STORIES_SUCCESS";
export const RETRIEVE_USERS_STORIES_FAIL = "RETRIEVE_USERS_STORIES_FAIL";

export const REQUEST_CREATE_STORIES_BACKGROUND = "REQUEST_CREATE_STORIES_BACKGROUND";
export const CREATE_STORIES_BACKGROUND_SUCCESS = "CREATE_STORIES_BACKGROUND_SUCCESS";
export const CREATE_STORIES_BACKGROUND_FAIL = "CREATE_STORIES_BACKGROUND_FAIL";

export const REQUEST_CREATE_STORIES_VIDEOS = "REQUEST_CREATE_STORIES_VIDEOS";
export const CREATE_STORIES_VIDEOS_SUCCESS = "CREATE_STORIES_VIDEOS_SUCCESS";
export const CREATE_STORIES_VIDEOS_FAIL = "CREATE_STORIES_VIDEOS_FAIL";

export const REQUEST_CREATE_STORIES_IMAGES = "REQUEST_CREATE_STORIES_IMAGES";
export const CREATE_STORIES_IMAGES_SUCCESS = "CREATE_STORIES_IMAGES_SUCCESS";
export const CREATE_STORIES_IMAGES_FAIL = "CREATE_STORIES_IMAGES_FAIL";

export const REQUEST_DELETE_STORIES = "REQUEST_DELETE_STORIES";
export const DELETE_STORIES_SUCCESS = "DELETE_STORIES_SUCCESS";
export const DELETE_STORIES_FAIL = "DELETE_STORIES_FAIL";