// import React from 'react';
// import HeaderBar from '../../components/users/header/HeaderWidget.js';
// import Sidebar from '../../components/users/sidebar/SidebarWidget.js';
// import RightSidebarWidget from '../../components/users/sidebar/RightSidebarWidget.js';

// import { Navigate, Route, Routes } from 'react-router-dom';

// import CoinsHome from '../users/coins/CoinsPage';
// import StoreCoins from '../users/coins/StoreCoinsPage';
// import LearnCoins from '../users/coins/LearnCoinsPage';
// import HistoryCoins from '../users/coins/HistoryCoinsPage';

// import Notes from '../users/notes/NotePage';
// import CreateNotes from '../users/notes/CreateNotePage';
// import EditNotes from '../users/notes/EditNotePage';

// import DetailsPosts from '../users/posts/DetailsPostsPage';
// import CommentsPosts from '../users/posts/CommentsPostsPage';
// import ReplyCommentsPosts from '../users/posts/ReplyCommentsPostsPage';
// import LikesPosts from '../users/posts/LikesPostsPage';
// import EditPosts from '../users/posts/EditPostsPage';
// import ReportPosts from '../users/posts/ReportPostsPage';
// import CreatePostsBackground from '../users/posts/CreatePostsBackgroundPage';
// import CreatePostsImages from '../users/posts/CreatePostsImagesPage';
// import CreatePostsLink from '../users/posts/CreatePostsLinkPage';
// import CreatePostsTeks from '../users/posts/CreatePostsTeksPage.js';
// import CreatePostsTvs from '../users/posts/CreatePostsTvsPage';
// import CreatePostsVideos from '../users/posts/CreatePostsVideosPage';

// import VideosProfilesPage from '../users/profiles/VideosProfilesPage';
// import PhotosProfilesPage from '../users/profiles/PhotosProfilePage';
// import TvsProfilesPage from '../users/profiles/TvsProfilesPage';
// import MomentsProfilesPage from '../users/profiles/MomentsProfilesPage';
// import DiaryProfilePage from '../users/birthday/BirthdayDiaryPage';

// import PostsSaved from '../users/saved/PostsSavedPage';
// import GallerySaved from '../users/saved/GallerySavedPage';

// import EventHome from '../users/event/EventHomePage';
// import MyEvent from '../users/event/MyEventPage';
// import InvitationEvent from '../users/event/InvitationEventPage';
// import DetailsEvent from '../users/event/DetailsEventPage';
// import EditEvent from '../users/event/EditEventPage';
// import CreateEvent from '../users/event/CreateEventPage';

// import GiftsStore from '../users/gifts/StoreGiftsPage';
// import MyGifts from '../users/gifts/MygiftsPage';
// import Gifts from '../users/gifts/GiftsPage';
// import SendGifts from '../users/gifts/SendGiftsPage';

// import AboutApp from '../users/menu/AboutAppPage';
// import AccountSetting from '../users/menu/AccountSettingPage';
// import ChangeEmail from '../users/menu/ChangeEmailPage';
// import ChangePassword from '../users/menu/ChangePasswordPage';
// import ChangeUsername from '../users/menu/ChangeUsernamePage';
// import Friend from '../users/menu/FriendPage';
// import Online from '../users/menu/OnlinePage';
// import ReportApp from '../users/menu/ReportAppPage';
// import UserCard from '../users/menu/UserCardPage';
// import LanguageMenu from '../users/menu/LanguagePage.js';

// import EditProfile from '../users/profiles/EditProfilePage';
// import Following from '../users/profiles/FollowedPage';
// import Follower from '../users/profiles/FollowerPage';
// import LoveProfile from '../users/profiles/LoveProfilePage';
// import Profile from '../users/profiles/ProfilePage';
// import Match from '../users/profiles/MatchPage';
// import ReportUser from '../users/profiles/ReportUserPage';
// import Suggestion from '../users/profiles/SuggestionPage';
// import VisitorProfile from '../users/profiles/VisitorProfilePage';

// import CommentsMoments from '../users/moments/CommentsMomentsPage';
// import CreateNewMoments from '../users/moments/CreateNewMomentsPage';
// import FavoriteMoments from '../users/moments/FavoriteMomentsPage';
// import MomentsHome from '../users/moments/MomentsHomePage';
// import MyMoments from '../users/moments/MymomentsPage';
// import LikesMoments from '../users/moments/LikesMomentsPage';
// import RoamingMoments from '../users/moments/RoamingMomentsPage';
// import ReportMoments from '../users/moments/ReportMomentsPage';

// import Group from '../users/group/GroupPage';
// import DetailsGroup from '../users/group/DetailsGroupPage';
// import InvitationGroup from '../users/group/InvitationGroupPage';
// import MemberGroup from '../users/group/MemberGroupPage';
// import MyGroup from '../users/group/MygroupPage';
// import EditGroup from '../users/group/EditGroupPage';
// import RequestJoinGroup from '../users/group/RequestJoinGroupPage';
// import ChangeCoverGroup from '../users/group/ChangeCoverGroupPage';
// import ChangePhotoGroup from '../users/group/ChangePhotoGroupPage';
// import CreateGroup from '../users/group/CreateGroupPage';
// import CreatePostsBackgroundGroup from '../users/group/posts/CreatePostsBackgroundGroupPage';
// import CreatePostsImagesGroup from '../users/group/posts/CreatePostsImagesGroupPage';
// import CreatePostsLinkGroup from '../users/group/posts/CreatePostsLinkGroupPage';
// import CreatePostsTeksGroup from '../users/group/posts/CreatePostsTeksGroupPage';
// import CreatePostsTvsGroup from '../users/group/posts/CreatePostsTvsGroupPage';
// import CreatePostsVideosGroup from '../users/group/posts/CreatePostsVideosGroupPage';

// import AccountLevel from '../users/account_level/AccountLevelPage';
// import FreeAccount from '../users/account_level/FreeAccountPage';
// import VipAccount from '../users/account_level/VipAccountPage';

// import Index from '../users/posts/PostsPage';
// import Explorer from '../users/posts/ExplorerPage';
// import Watch from '../users/posts/WatchPage';
// import Tvs from '../users/posts/TvsPage';
// import Notification from '../users/notification/NotificationPage';

// import Menu from '../users/menu/MenusAccountPage';
// import SearchPage from '../users/search/SearchPage';

// import ConversationPage from '../users/chatting/ConversationPage';
// import BottomNavigationWidget from '../../components/users/header/BottomNavigationWidget.js';
// import LoginPage from '../welcome/LoginPage.js';
// import RegisterPage from '../welcome/RegisterPage.js';
// import ForgetPage from '../welcome/ForgetPage.js';
// import { useTranslation } from 'react-i18next';
// import { Helmet } from 'react-helmet';

// import ForYouPage from './posts/ForYouPage.js';
// import PostsLink from './posts/PostsLinkPage.js';
// import PostsBg from './posts/PostsBackgroundPage.js';
// import PopularPostsPage from './posts/PopularPostsPage.js';
// import MemoriesPage from './posts/MemoriesPage.js';
// import CallbackPage from '../welcome/CallbackPage.js';


// export default function NavigationPage() {
//     const { t, i18n } = useTranslation();
//     return (
//         <div>
//             <Helmet>
//                 <title>Trafalines</title>
//                 <meta name="description" content="Trafalines berbagi foto, video, dan kenangan lain." />
//                 <meta name="keywords" content="cloud storage, berbagi foto, berbagi video, kenangan, Trafalines" />
//                 <meta name="author" content="Trafalines" />
//                 <meta name="robots" content="index, follow" />

//                 {/* Open Graph Meta Tags */}
//                 <meta property="og:title" content="Trafalines" />
//                 <meta property="og:description" content="Trafalines berbagi foto, video, dan kenangan lain." />
//                 <meta property="og:image" content="/logo.png" />
//                 <meta property="og:url" content={window.location.href} />
//                 <meta property="og:type" content="website" />
//             </Helmet>

//             <div className="bg-white dark:bg-dark-main">
//                 <Routes>
//                     <Route exact path='/official' component={() => {
//                         window.location.href = 'https://official.trafalines.com/';
//                         return null;
//                     }} />
//                     <Route exact path='/accommodation' component={() => {
//                         window.location.href = 'https://accommodation.trafalines.com/';
//                         return null;
//                     }} />
//                     <Route exact path='/restos' component={() => {
//                         window.location.href = 'https://restos.trafalines.com/';
//                         return null;
//                     }} />
//                     <Route exact path='/services' component={() => {
//                         window.location.href = 'https://services.trafalines.com/';
//                         return null;
//                     }} />

//                     <Route exact path='/marketplace' component={() => {
//                         window.location.href = 'https://marketplace.trafalines.com/';
//                         return null;
//                     }} />

//                     <Route exact path='/ticket' component={() => {
//                         window.location.href = 'https://ticket.trafalines.com/';
//                         return null;
//                     }} />

//                     <Route exact path='/blog' component={() => {
//                         window.location.href = 'https://blog.trafalines.com/';
//                         return null;
//                     }} />

//                     <Route path="/auth/callback" component={CallbackPage} />
//                     {/* <Navigate exact from='/' to="/auth/login" /> */}
//                     <Route path="/" element={<Navigate to="/auth/login" replace />} />
//                     <Route exact path="/auth/login" component={LoginPage} />
//                     <Route exact path="/auth/register" component={RegisterPage} />
//                     <Route exact path="/auth/forget-password" component={ForgetPage} />
//                     <div className='"bg-white dark:bg-dark-main'>
//                         <HeaderBar />
//                         <BottomNavigationWidget />
//                         {/* <!-- MAIN --> */}
//                         <div className="flex justify-center min-h-screen pb-[60px]">
//                             {/* <!-- LEFT MENU --> */}
//                             <Sidebar />
//                             {/* <!-- END LEFT MENU --> */}

//                             {/* <!-- MAIN CONTENT --> */}
//                             <Route path="/memories" component={MemoriesPage} />
//                             <Route path="/foryou" component={ForYouPage} />
//                             <Route path="/home" component={Index} />
//                             <Route path="/explorer" component={Explorer} />
//                             <Route path="/popular" component={PopularPostsPage} />
//                             <Route path="/watch" component={Watch} />
//                             <Route path="/tvs" component={Tvs} />
//                             <Route path="/background" component={PostsBg} />
//                             <Route path="/link" component={PostsLink} />
//                             <Route path="/post/:id" component={DetailsPosts} />


//                             {/* <!-- END MAIN CONTENT --> */}
//                             {/* <!-- RIGHT MENU --> */}
//                             <RightSidebarWidget />
//                             {/* <!-- END RIGHT MENU --> */}
//                         </div>
//                         {/* <!-- ENDMAIN --> */}
//                     </div>
//                     {/* nav */}
//                 </Routes>
//             </div>
//         </div>

//     );

// }

import React from 'react';
import HeaderBar from '../../components/users/header/HeaderWidget';
import Sidebar from '../../components/users/sidebar/SidebarWidget';
import RightSidebarWidget from '../../components/users/sidebar/RightSidebarWidget';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import CoinsHome from '../users/coins/CoinsPage';
import StoreCoins from '../users/coins/StoreCoinsPage';
import LearnCoins from '../users/coins/LearnCoinsPage';
import HistoryCoins from '../users/coins/HistoryCoinsPage';

import Notes from '../users/notes/NotePage';
import CreateNotes from '../users/notes/CreateNotePage';
import EditNotes from '../users/notes/EditNotePage';

import DetailsPosts from '../users/posts/DetailsPostsPage';
import CommentsPosts from '../users/posts/CommentsPostsPage';
import ReplyCommentsPosts from '../users/posts/ReplyCommentsPostsPage';
import LikesPosts from '../users/posts/LikesPostsPage';
import EditPosts from '../users/posts/EditPostsPage';
import ReportPosts from '../users/posts/ReportPostsPage';
import CreatePostsBackground from '../users/posts/CreatePostsBackgroundPage';
import CreatePostsImages from '../users/posts/CreatePostsImagesPage';
import CreatePostsLink from '../users/posts/CreatePostsLinkPage';
import CreatePostsTeks from '../users/posts/CreatePostsTeksPage';
import CreatePostsTvs from '../users/posts/CreatePostsTvsPage';
import CreatePostsVideos from '../users/posts/CreatePostsVideosPage';

import VideosProfilesPage from '../users/profiles/VideosProfilesPage';
import PhotosProfilesPage from '../users/profiles/PhotosProfilePage';
import TvsProfilesPage from '../users/profiles/TvsProfilesPage';
import MomentsProfilesPage from '../users/profiles/MomentsProfilesPage';
import DiaryProfilePage from '../users/birthday/BirthdayDiaryPage';

import PostsSaved from '../users/saved/PostsSavedPage';
import GallerySaved from '../users/saved/GallerySavedPage';

import EventHome from '../users/event/EventHomePage';
import MyEvent from '../users/event/MyEventPage';
import InvitationEvent from '../users/event/InvitationEventPage';
import DetailsEvent from '../users/event/DetailsEventPage';
import EditEvent from '../users/event/EditEventPage';
import CreateEvent from '../users/event/CreateEventPage';

import GiftsStore from '../users/gifts/StoreGiftsPage';
import MyGifts from '../users/gifts/MygiftsPage';
import Gifts from '../users/gifts/GiftsPage';
import SendGifts from '../users/gifts/SendGiftsPage';

import AboutApp from '../users/menu/AboutAppPage';
import AccountSetting from '../users/menu/AccountSettingPage';
import ChangeEmail from '../users/menu/ChangeEmailPage';
import ChangePassword from '../users/menu/ChangePasswordPage';
import ChangeUsername from '../users/menu/ChangeUsernamePage';
import Friend from '../users/menu/FriendPage';
import Online from '../users/menu/OnlinePage';
import ReportApp from '../users/menu/ReportAppPage';
import UserCard from '../users/menu/UserCardPage';
import LanguageMenu from '../users/menu/LanguagePage';

import EditProfile from '../users/profiles/EditProfilePage';
import Following from '../users/profiles/FollowedPage';
import Follower from '../users/profiles/FollowerPage';
import LoveProfile from '../users/profiles/LoveProfilePage';
import Profile from '../users/profiles/ProfilePage';
import Match from '../users/profiles/MatchPage';
import ReportUser from '../users/profiles/ReportUserPage';
import Suggestion from '../users/profiles/SuggestionPage';
import VisitorProfile from '../users/profiles/VisitorProfilePage';

import CommentsMoments from '../users/moments/CommentsMomentsPage';
import CreateNewMoments from '../users/moments/CreateNewMomentsPage';
import FavoriteMoments from '../users/moments/FavoriteMomentsPage';
import MomentsHome from '../users/moments/MomentsHomePage';
import MyMoments from '../users/moments/MymomentsPage';
import LikesMoments from '../users/moments/LikesMomentsPage';
import RoamingMoments from '../users/moments/RoamingMomentsPage';
import ReportMoments from '../users/moments/ReportMomentsPage';

import Group from '../users/group/GroupPage';
import DetailsGroup from '../users/group/DetailsGroupPage';
import InvitationGroup from '../users/group/InvitationGroupPage';
import MemberGroup from '../users/group/MemberGroupPage';
import MyGroup from '../users/group/MygroupPage';
import EditGroup from '../users/group/EditGroupPage';
import RequestJoinGroup from '../users/group/RequestJoinGroupPage';
import ChangeCoverGroup from '../users/group/ChangeCoverGroupPage';
import ChangePhotoGroup from '../users/group/ChangePhotoGroupPage';
import CreateGroup from '../users/group/CreateGroupPage';
import CreatePostsBackgroundGroup from '../users/group/posts/CreatePostsBackgroundGroupPage';
import CreatePostsImagesGroup from '../users/group/posts/CreatePostsImagesGroupPage';
import CreatePostsLinkGroup from '../users/group/posts/CreatePostsLinkGroupPage';
import CreatePostsTeksGroup from '../users/group/posts/CreatePostsTeksGroupPage';
import CreatePostsTvsGroup from '../users/group/posts/CreatePostsTvsGroupPage';
import CreatePostsVideosGroup from '../users/group/posts/CreatePostsVideosGroupPage';

import AccountLevel from '../users/account_level/AccountLevelPage';
import FreeAccount from '../users/account_level/FreeAccountPage';
import VipAccount from '../users/account_level/VipAccountPage';

import Index from '../users/posts/PostsPage';
import Explorer from '../users/posts/ExplorerPage';
import Watch from '../users/posts/WatchPage';
import Tvs from '../users/posts/TvsPage';
import Notification from '../users/notification/NotificationPage';

import Menu from '../users/menu/MenusAccountPage';
import SearchPage from '../users/search/SearchPage';

import ConversationPage from '../users/chatting/ConversationPage';
import BottomNavigationWidget from '../../components/users/header/BottomNavigationWidget';
import LoginPage from '../welcome/LoginPage';
import RegisterPage from '../welcome/RegisterPage';
import ForgetPage from '../welcome/ForgetPage';
import CallbackPage from '../welcome/CallbackPage';

import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import ForYouPage from './posts/ForYouPage';
import PostsLink from './posts/PostsLinkPage';
import PostsBg from './posts/PostsBackgroundPage';
import PopularPostsPage from './posts/PopularPostsPage';
import MemoriesPage from './posts/MemoriesPage';

export default function NavigationPage() {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const hideSidebarRoutes = ['/auth/login', '/auth/register', '/auth/forget-password', '/auth/callback'];

    const shouldHideSidebar = hideSidebarRoutes.includes(location.pathname);

    return (

        <div>
            <div className="bg-white dark:bg-dark-main">
                {!shouldHideSidebar && <Sidebar />}
                {!shouldHideSidebar && <HeaderBar />}
                {!shouldHideSidebar && <RightSidebarWidget />}
                {!shouldHideSidebar && <BottomNavigationWidget />}
                <main className="flex justify-center min-h-screen pb-[60px]">
                    <Routes>
                        <Route path="/official" element={<Navigate to="https://official.trafalines.com/" replace />} />
                        <Route path="/accommodation" element={<Navigate to="https://accommodation.trafalines.com/" replace />} />
                        <Route path="/restos" element={<Navigate to="https://restos.trafalines.com/" replace />} />
                        <Route path="/services" element={<Navigate to="https://services.trafalines.com/" replace />} />
                        <Route path="/marketplace" element={<Navigate to="https://marketplace.trafalines.com/" replace />} />
                        <Route path="/ticket" element={<Navigate to="https://ticket.trafalines.com/" replace />} />
                        <Route path="/blog" element={<Navigate to="https://blog.trafalines.com/" replace />} />

                        <Route path="/auth/callback" element={<CallbackPage />} />
                        <Route path="/" element={<Navigate to="/auth/login" replace />} />
                        <Route path="/auth/login" element={<LoginPage />} />
                        <Route path="/auth/register" element={<RegisterPage />} />
                        <Route path="/auth/forget-password" element={<ForgetPage />} />

                        <Route path="/memories" element={<MemoriesPage />} />
                        <Route path="/foryou" element={<ForYouPage />} />
                        <Route path="/home" element={<Index />} />
                        <Route path="/explorer" element={<Explorer />} />
                        <Route path="/popular" element={<PopularPostsPage />} />
                        <Route path="/watch" element={<Watch />} />
                        <Route path="/tvs" element={<Tvs />} />
                        <Route path="/background" element={<PostsBg />} />
                        <Route path="/link" element={<PostsLink />} />
                        <Route path="/post/:id" element={<DetailsPosts />} />
                    </Routes>
                </main>
            </div>
        </div>
    );
}
