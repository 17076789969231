import http from '../http-common';
import axios from 'axios';
import authHeader from '../auth-header';
import { API_URL } from '../base_url';

const getAllPosts = (page = 1) => {
    return axios.get(`${API_URL}/posts?page=${page}`, { headers: authHeader() });
};

const getPopularPosts = (page = 1) => {
    return http.get(`/popular?page=${page}`);
}

const getTvsPosts = (page = 1) => {
    return http.get(`/tvs?page=${page}`);
}

const getVideosPosts = (page = 1) => {
    return axios.get(`${API_URL}/watch?page=${page}`, { headers: authHeader() });
}

const getBgPosts = (page = 1) => {
    return http.get(`/background?page=${page}`);
}

const getLinkPosts = (page = 1) => {
    return http.get(`/link?page=${page}`);
}

const getUsersPosts = users_id => {
    return http.get('/users/' + users_id + '/posts');
};

const getPosts = id => {
    return http.get('/posts/' + id);
};

const getLikesPosts = (id, page) => {
    return http.get(`/posts/${id}/likes?page=${page}`);
};

const getCommentsPosts = (id, page) => {
    return http.get(`/posts/${id}/comments?page=${page}`);
};

const getLikesCommentsPosts = (id, page) => {
    return http.get(`/comments-posts/${id}/likes?page=${page}`);
};

const getLikesReplyCommentsPosts = (id, page) => {
    return http.get(`/reply-comments-posts/${id}/likes?page=${page}`);
};

const createPostsTeks = data => {
    return http.post('/posts/store-posts-teks', data);
};

const createPostsBackground = data => {
    return http.post('/posts/store-posts-background', data);
};

const createPostsImages = data => {
    return http.post('/posts/store-posts-images', data);
}

const createPostsVideos = data => {
    return http.post('/posts/store-posts-videos', data);
};

const createPostsLink = data => {
    return http.post('/posts/store-posts-link', data);
};

const createPostsTvs = data => {
    return http.post('/posts/store-posts-tvs', data);
};

const createLikesPosts = data => {
    return http.post('/posts/store-likes', data);
};

const createCommentsPostsTeks = data => {
    return http.post('/posts/comments/store-comments-teks', data);
};

const createCommentsPostsSticker = data => {
    return http.post('/posts/comments/store-comments-sticker', data);
};

const createReplyCommentsPostsTeks = data => {
    return http.post('/posts/comments/reply/store-reply-teks', data);
};

const createReplyCommentsPostsSticker = data => {
    return http.post('/posts/comments/reply/store-reply-sticker', data);
};

const createLikesCommentsPosts = data => {
    return http.post('/posts/comments/store-likes', data);
};

const createLikesReplyCommentsPosts = data => {
    return http.post('/posts/comments/reply/store-likes', data);
};

const createReportPosts = data => {
    return http.post('/posts/store-report', data);
};

const createSavePosts = data => {
    return http.post('/posts/store-save-posts', data);
};

const updatePostsTeks = (id, data) => {
    return http.put('/posts/' + id + '/posts-teks-update', data);
};

const updatePostsBackground = (id, data) => {
    return http.put('/posts/' + id + '/posts-background-update', data);
};

const updatePostsImages = (id, data) => {
    return http.put('/posts/' + id + '/posts-images-update', data);
};

const updatePostsVideos = (id, data) => {
    return http.put('/posts/' + id + '/posts-videos-update', data);
};

const updatePostsLink = (id, data) => {
    return http.put('/posts/' + id + '/posts-link-update', data);
};

const updatePostsTvs = (id, data) => {
    return http.put('/posts/' + id + '/posts-tvs-update', data);
};

const deletePosts = id => {
    return http.put('/posts/' + id + '/delete');
};

const deleteCommentsPosts = id => {
    return http.put('/posts/comments/' + id + '/delete');
};

const deleteReplyCommentsPosts = id => {
    return http.put('/posts/comments/reply/' + id + '/delete');
};

const PostsService = {
    getAllPosts,
    getPopularPosts,
    getTvsPosts,
    getVideosPosts,
    getBgPosts,
    getLinkPosts,
    getUsersPosts,
    getPosts,
    getLikesPosts,
    getCommentsPosts,
    getLikesCommentsPosts,
    getLikesReplyCommentsPosts,
    createPostsTeks,
    createPostsBackground,
    createPostsImages,
    createPostsVideos,
    createPostsLink,
    createPostsTvs,
    createLikesPosts,
    createCommentsPostsTeks,
    createCommentsPostsSticker,
    createReplyCommentsPostsTeks,
    createReplyCommentsPostsSticker,
    createLikesCommentsPosts,
    createLikesReplyCommentsPosts,
    createReportPosts,
    createSavePosts,
    updatePostsTeks,
    updatePostsBackground,
    updatePostsImages,
    updatePostsVideos,
    updatePostsLink,
    updatePostsTvs,
    deletePosts,
    deleteCommentsPosts,
    deleteReplyCommentsPosts
}

export default PostsService;