import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveMemories } from "../../../actions/home/home";
import PostsPlaceholder from "../../../components/placeholder/home/PostsPlaceholder";
import PostsWidget from "../../../components/users/posts/PostsWidget";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function MemoriesPage() {
    const { isLoading, memoriesList } = useSelector(state => state.home);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(retrieveMemories());
    }, [dispatch])

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className="w-full lg:w-2/3 xl:w-2/5 pt-8 lg:pt-16 mt-2 lg:mt-0">
            <div className="xl:hidden">
                <div className='fixed top-0 bg-white dark:bg-dark-second px-3 h-max md:h-14 w-full shadow flex flex-col md:flex-row md:justify-between z-50 border-b dark:border-dark-third'>
                    <div className='flex items-center justify-between'>
                        <div className='flex'>
                            <button onClick={handleGoBack} className="space-x-2 ml-3 mr-3 justify-center items-center text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">
                                <FontAwesomeIcon icon={faArrowLeft} className="text-gray-600 dark:text-dark-txt text-xl" />
                            </button>
                            <div className="font-normal text-md py-2 text-gray-600 dark:text-dark-txt">Memories</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex items-center justify-center'>
                <div className='w-full lg:w-96'>
                    {isLoading ? (
                        <PostsPlaceholder />
                    ) : (
                        Array.isArray(memoriesList) && memoriesList.length > 0 ? (
                            memoriesList.map(post => (
                                post && post.id ? (
                                    <PostsWidget key={post.id} post={post} />
                                ) : (
                                    <div key="error">Data post tidak valid</div>
                                )
                            ))
                        ) : (
                            <div>Tidak ada postingan untuk ditampilkan</div>
                        )
                    )}
                </div>
            </div>
        </div>
    );

}