import {
    REQUEST_RETRIEVE_POSTS_SERVICES,
    RETRIEVE_POSTS_SERVICES_SUCCESS,
    RETRIEVE_POSTS_SERVICES_FAIL,
    REQUEST_CREATE_POSTS_BACKGROUND_SERVICES,
    CREATE_POSTS_BACKGROUND_SERVICES_SUCCESS,
    CREATE_POSTS_BACKGROUND_SERVICES_FAIL,
    REQUEST_CREATE_POSTS_TEKS_SERVICES,
    CREATE_POSTS_TEKS_SERVICES_SUCCESS,
    CREATE_POSTS_TEKS_SERVICES_FAIL,
    REQUEST_CREATE_POSTS_IMAGES_SERVICES,
    CREATE_POSTS_IMAGES_SERVICES_SUCCESS,
    CREATE_POSTS_IMAGES_SERVICES_FAIL,
    REQUEST_CREATE_POSTS_VIDEOS_SERVICES,
    CREATE_POSTS_VIDEOS_SERVICES_SUCCESS,
    CREATE_POSTS_VIDEOS_SERVICES_FAIL,
    REQUEST_CREATE_POSTS_LINK_SERVICES,
    CREATE_POSTS_LINK_SERVICES_SUCCESS,
    CREATE_POSTS_LINK_SERVICES_FAIL,
    REQUEST_CREATE_POSTS_TVS_SERVICES,
    CREATE_POSTS_TVS_SERVICES_SUCCESS,
    CREATE_POSTS_TVS_SERVICES_FAIL
} from '../../../actions/services/TypesPostsServices';

const initialState = {
    listPosts: [],
    isLoading: false,
};

function postsServiceReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case REQUEST_RETRIEVE_POSTS_SERVICES:
            return {
                ...state,
                isLoading: true,
            }
        case RETRIEVE_POSTS_SERVICES_SUCCESS:
            return {
                ...state,
                listPosts: payload,
                isLoading: false
            }
        case RETRIEVE_POSTS_SERVICES_FAIL:
            return {
                ...state,
                isLoading: false
            }
        case REQUEST_CREATE_POSTS_TEKS_SERVICES:
            return {
                ...state
            }
        case CREATE_POSTS_TEKS_SERVICES_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_POSTS_TEKS_SERVICES_FAIL:
            return {
                ...state
            }
        case REQUEST_CREATE_POSTS_BACKGROUND_SERVICES:
            return {
                ...state
            }
        case CREATE_POSTS_BACKGROUND_SERVICES_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_POSTS_BACKGROUND_SERVICES_FAIL:
            return {
                ...state
            }
        case REQUEST_CREATE_POSTS_IMAGES_SERVICES:
            return {
                ...state
            }
        case CREATE_POSTS_IMAGES_SERVICES_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_POSTS_IMAGES_SERVICES_FAIL:
            return {
                ...state
            }
        case REQUEST_CREATE_POSTS_LINK_SERVICES:
            return {
                ...state
            }
        case CREATE_POSTS_LINK_SERVICES_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_POSTS_LINK_SERVICES_FAIL:
            return {
                ...state
            }
        case REQUEST_CREATE_POSTS_VIDEOS_SERVICES:
            return {
                ...state,
            }
        case CREATE_POSTS_VIDEOS_SERVICES_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_POSTS_VIDEOS_SERVICES_FAIL:
            return {
                ...state
            }
        case REQUEST_CREATE_POSTS_TVS_SERVICES:
            return {
                ...state
            }
        case CREATE_POSTS_TVS_SERVICES_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_POSTS_TVS_SERVICES_FAIL:
            return {
                ...state
            }
        default:
            return state;
    }
}

export default postsServiceReducer;