import React, { useState } from 'react';
import { faHeart, faComment, faShare, faBookmark, faEllipsisH, faMapMarker, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/id';
import MapComponent from './MapsComponent';
import ImageGallery from './ImagesGallery';
import LinkPreview from './LinkPreview';
import ShareComponent from '../util/ShareComponent';
import MenuPostComponent from './MenuPostsComponent';
import { useNavigate } from 'react-router-dom';

function PostsWidget({ post }) {
    const [isShareDialogOpen, setShareDialogOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const now = moment().startOf('day');
    const createdAt = moment(post.created_at).startOf('day');
    const isSameMonthAndDay = (now.month() === createdAt.month()) &&
        (now.date() === createdAt.date());

    const isOlderThanAYear = now.diff(createdAt, 'years') >= 1;
    const yearDifference = now.diff(createdAt, 'years');
    const yearText = yearDifference === 1 ? 'year' : 'years';
    const year = `${yearDifference} ${yearText}`;

    const formattedDate = isOlderThanAYear ? createdAt.format('D MMMM YYYY') : createdAt.fromNow();
    const formatShare = createdAt.format('D MMMM YYYY');

    const message = encodeURIComponent("Shared from social network v2");
    const shareUrl = `https://m.trafalines.com/post/${post.id}?source=app&message=${message}`;
    const shareTitle = `Check out this post by ${post.users.username} on Trafalines Social Network V2, posted on ${formatShare}:`;

    const toggleShareDialog = () => setShareDialogOpen(!isShareDialogOpen);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const history = useNavigate();

    const handleDetail = () => {
        history(`/post/${post.id}`);
        toggleMenu();
    };

    const handleEdit = () => {
        console.log('Edit Post');
        toggleMenu();
    };

    const handleDelete = () => {
        console.log('Delete Post');
        toggleMenu();
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shareUrl);
        alert('Link copied to clipboard!');
    };

    return (
        <div className="shadow bg-white dark:bg-dark-second dark:text-dark-txt mt-4 rounded-none lg:rounded-lg xl:rounded-lg">
            {isSameMonthAndDay && yearDifference > 0 && (
                <div className='text-center text-sm pt-1 pb-2'>
                    <FontAwesomeIcon icon={faClock} className="text-gray-400 text-sm mr-2" />
                    <span className='text-gray-400'>{year}</span>
                </div>
            )}

            <div className="flex items-center justify-between px-4 py-2">
                <div className="flex space-x-2 items-center">
                    <div className="relative">
                        <Link to="/profile">
                            <img src={post.users.profile_img_small} alt="Profile" className="w-10 h-10 rounded-full" />
                        </Link>
                        {post.users.is_online && (
                            <span className="bg-green-500 w-3 h-3 rounded-full absolute left-[-0.5px] top-[-0.5px] border-white border-2"></span>
                        )}
                    </div>
                    <div>
                        <Link to="/profile">
                            <div className="font-normal text-xs">
                                {post.users.username}
                            </div>
                        </Link>
                        <span className="text-xs text-gray-500">
                            {formattedDate}
                        </span>
                    </div>
                </div>

                <div onClick={toggleMenu} className="w-8 h-8 grid place-items-center text-xl text-gray-500 hover:bg-gray-200 dark:text-dark-txt dark:hover:bg-dark-third rounded-full cursor-pointer">
                    <FontAwesomeIcon icon={faEllipsisH} className="text-gray-400 text-xl" />
                </div>
                <MenuPostComponent
                    post={post}
                    isMenuOpen={isMenuOpen}
                    toggleMenu={toggleMenu}
                    onDetail={handleDetail}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                />
            </div>

            {/* Post content based on type */}
            <div className="py-2">
                {post.posts_types === 'images' ? (
                    <ImageGallery posts={post} />
                ) : post.posts_types === 'videos' ? (
                    <video
                        className="w-full h-full object-cover max-h-[500px]"
                        controls
                        src={post.posts_videos.files_videos}
                        onError={(e) => e.currentTarget.src = '/path/to/fallback-video.mp4'}
                    ></video>
                ) : post.posts_types === 'tvs' ? (
                    <video
                        className="w-full h-full object-cover max-h-[500px]"
                        controls
                        src={post.posts_tvs.files_videos}
                        onError={(e) => e.currentTarget.src = '/path/to/fallback-video.mp4'}
                    ></video>
                ) : post.posts_types === 'background' ? (
                    <div className="relative w-full h-full">
                        <img
                            className="w-full h-full object-cover"
                            src={post.posts_background.background_ori}
                            alt=""
                            onError={(e) => e.currentTarget.src = '/path/to/fallback-image.jpg'}
                        />
                        <div className="absolute inset-0 flex items-center justify-center">
                            <span className="text-white p-4 text-center">{post.posts_background.body}</span>
                        </div>
                    </div>
                ) : post.posts_types === 'link' ? (
                    <LinkPreview url={post.posts_link.url} />
                ) : post.posts_types === 'location' ? (
                    <>
                        <MapComponent latitude={post.posts_location.latitude} longitude={post.posts_location.longitude} />
                        <div className="flex items-center space-x-2 p-3">
                            <FontAwesomeIcon icon={faMapMarker} className="text-red-500 text-xl" />
                            <p className="text-gray-700 dark:text-white">{post.posts_location.place_name}</p>
                        </div>
                    </>
                ) : (
                    <p></p>
                )}
            </div>

            {/* Post caption */}
            {post.caption && post.caption.trim() !== "" && (
                <div className="text-start text-sm px-4 py-2">
                    {post.caption}
                </div>
            )}

            {/* Post interactions */}
            <div className="pb-0 pt-0 px-4">
                <div className="border border-gray-200 dark:border-dark-third border-l-0 border-r-0 border-b-0 border-t-0 py-1">
                    <div className="flex items-center justify-between">
                        <div className="flex">
                            <div className="space-x-2 ml-3 mr-3 justify-center items-center text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">
                                <FontAwesomeIcon icon={faHeart} className={`${post.is_liked ? 'text-red-500' : 'text-gray-400'} text-xl`} />
                            </div>
                            <Link to="/posts/comments" className="space-x-2 ml-3 mr-3 justify-center items-center text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">
                                <FontAwesomeIcon icon={faComment} className="text-gray-400 text-xl" />
                            </Link>
                            <div onClick={toggleShareDialog} className="space-x-2 ml-3 mr-3 justify-center items-center text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">
                                <FontAwesomeIcon icon={faShare} className="text-gray-400 text-xl" />
                            </div>
                            <ShareComponent
                                isShareDialogOpen={isShareDialogOpen}
                                toggleShareDialog={toggleShareDialog}
                                shareUrl={shareUrl}
                                shareTitle={shareTitle}
                                copyToClipboard={copyToClipboard}
                            />
                        </div>
                        <div className="flex">
                            <div className="space-x-2 ml-3 mr-3 justify-center items-center text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">
                                <FontAwesomeIcon icon={faBookmark} className={`${post.is_saved ? 'text-green-500' : 'text-gray-400'} text-xl`} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Post likes and comments count */}
            <div className="px-4 pb-4">
                <div className="flex items-center justify-between">
                    <div className="text-gray-500 dark:text-dark-txt">
                        <Link to="/posts/likes"><span className="mr-2 text-sm">{post.likes_count} likes</span></Link>
                        <Link to="/posts/comments"><span className='text-sm'>{post.comments_count} comments</span></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PostsWidget;
