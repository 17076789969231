import React from "react";
import { Link } from 'react-router-dom';
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

function ListContacts({ contacts }) {
    const { t, i18n } = useTranslation();
    return (contacts.length === 0 ? (
        <p>No Contacts</p>
    ) : (
        <div>
            <div className="flex justify-between items-center px-4 pt-0 text-gray-500 dark:text-dark-txt">
                <span className="font-normal text-sm">{t('label_contacts')}</span>
            </div>
            <ul className="p-2">
                {contacts &&
                    contacts.map((contact, index) => (
                        <li>
                            <Link to="profile" className="flex items-center justify-between space-x-4 p-2 hover:bg-gray-200 dark:hover:bg-dark-third dark:text-dark-txt rounded-lg cursor-pointer">
                                <div className='flex'>
                                    <div className="relative">
                                        <img src={contact.profile_img_small} alt="" className="rounded-full  object-cover w-8 h-8" />
                                        {contact.is_online === true ? (
                                            <span className="bg-green-500 w-3 h-3 rounded-full absolute left-0 top-0 border-white border-2"></span>
                                        ) : null}
                                    </div>
                                    <div className='ml-4 mt-1'>
                                        <span className="font-normal text-sm">{contact.username}</span>
                                    </div>
                                </div>

                                <div className='flex'>
                                    <span>  <button className="w-full bg-blue-400 hover:bg-blue-500  dark:bg-dark-second rounded-full text-white font-bold py-2 px-2 focus:outline-none focus:shadow-outline">
                                        <FontAwesomeIcon icon={faComments} /></button></span>
                                </div>
                            </Link>
                        </li>
                    ))}
            </ul>
        </div>
    ))
}

export default ListContacts;