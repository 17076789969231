import { combineReducers } from "redux";
import profile from "./users/account/ProfileReducers";
import auctions from "./users/auctions/AuctionsReducers";
import birthday from "./users/birthday/BirthdayReducers";
import event from "./users/event/EventReducers";
import group from "./users/group/GroupReducers";
import groupsMessenger from "./users/groups_messenger/GroupsMessengerReducers";
import messenger from "./users/messenger/MessengerReducers";
import moments from "./users/moments/MomentsReducers";
import notes from "./users/notes/NotesReducers";
import posts from "./users/posts/PostsReducers";
import products from "./users/products/ProductsReducers";
import stories from "./users/stories/StoriesReducers";
import restos from "./users/restos/RestosReducers";
import products_restos from "./users/restos/ProductsRestosReducers";
import posts_restos from "./users/restos/PostsRestosReducers";
import services from "./users/services/ServicesReducers";
import products_services from "./users/services/ProductsServicesReducers";
import posts_services from "./users/services/PostsServicesReducers";

import auth from "./auth/auth";
import message from "./auth/message";
import home from "./users/home/HomeReducers";

export default combineReducers({
    auth,
    message,
    home,
    // profile,
    // auctions,
    // birthday,
    // event,
    // group,
    // groupsMessenger,
    // messenger,
    // moments,
    // notes,
    posts,
    // products,
    stories,
    // restos,
    // products_restos,
    // posts_restos,
    // services,
    // products_services,
    // posts_services
});