import axios from 'axios';
import authHeader from './auth-header';
import { API_URL } from "./base_url";

class RecomendedService {
    getForYou= async(page=1) =>{
        return axios.get(`${API_URL}/foryou?page=${page}`, { headers: authHeader() });
    }

    getContacts() {
        return axios.get(API_URL + '/contacts', { headers: authHeader() });
    }

    getSuggestionPeople() {
        return axios.get(API_URL + '/suggestion-people', { headers: authHeader() });
    }

    previewsUrl = data => {
        return axios.post(API_URL + '/previews-url', data, { headers: authHeader() });
    };

    getMemories() {
        return axios.get(API_URL + '/memories', { headers: authHeader() });
    }
}

export default new RecomendedService();