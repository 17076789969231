import React from "react";

function HomeStoriesPlaceholder() {
    const rows = Array(6).fill().map((_, i) => (
        <li className="flex flex-col items-center">
            <button className="flex items-center justify-center rounded-full object-cover bg-gray-100 dark:bg-gray-700 w-20 h-20">
                <div style={{ width: '74px', height: '74px' }} class="rounded-full object-cover bg-gray-100 dark:bg-gray-700 border-4"></div>
            </button>
            <span class="mb-0 mt-1 text-xs w-16 flex justify-center items-center dark:text-dark-txt text-center"><div className=" bg-gray-100 dark:bg-gray-700 h-2 w-6 text-center"></div></span>
        </li>
    ));
    return (
        <ul className="flex animate-pulse space-x-2 px-2 overflow-hidden">
            {rows}
        </ul>
    )
}

export default HomeStoriesPlaceholder;