export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.accessToken) {
        return {
            'TokenApp': 'cHbOpF3QWtISkuaUCEOuKXHY8iPhD5afUx5CQ2nUt4U7',
            'Authorization': 'Bearer' + user.accessToken
        };
    } else {
        return {
            'TokenApp': 'cHbOpF3QWtISkuaUCEOuKXHY8iPhD5afUx5CQ2nUt4U7'
        };
    }


}