import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from "../../actions/auth/auth";
import { useNavigate } from "react-router-dom";

import Logo from '../../assets/images/icon.png';
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import GoogleLoginButton from '../../components/GoogleLoginButton';


export default function LoginPage() {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isEnable, setEnable] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();

    const handleKeyUp = () => {
        if (email.length > 0 && password.length >= 6) setEnable(false)
        else setEnable(true);
    }

    useEffect(() => {
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true);
        dispatch(login(email, password))
            .then((res) => {
                setIsLoading(false);
                console.log(res);
                navigate("/home");
                window.location.reload();
            })
            .catch(() => {
                setIsLoading(false)
            })
    };

    const handleGoogleLogin = async () => {
        try {
            const response = await fetch('https://api.trafalines.com/auth/redirect');
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            if (data.url) {
                window.location.href = data.url;
            } else {
                console.error('No URL found in the response');
            }
        } catch (error) {
            console.error('Error fetching Google login URL:', error);
        }
    };
    return (
        <div className="flex items-center justify-center h-screen bg-white dark:bg-dark-main">
            <div className="w-full">
                <form onSubmit={handleSubmit} className="bg-white dark:bg-dark-second shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div className="flex items-center justify-center">
                        <img src='/img/icon.png' alt="Trafalines logo" className="w-20 sm:w-10 lg:w-20 h-auto" />
                    </div>
                    {message && (
                        <div className="form-group text-center m-2">
                            <div className="alert alert-danger dark:text-white" role="alert">
                                {message}
                            </div>
                        </div>
                    )}
                    <br />
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">Email</label>
                        <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            id="email-input"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onKeyUp={handleKeyUp}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">Password</label>
                        <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            id="password-input"
                            type="password"
                            placeholder="******************"
                            onKeyUp={handleKeyUp}
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                        />
                    </div>

                    {isLoading ? (
                        <button
                            className="w-full mt-2 bg-transparent text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            id="button-input"
                            disabled
                            type='button'
                        >
                            <div role="status" className="text-center flex justify-center items-center">
                                <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin text-center dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>
                        </button>
                    ) : (
                        <button
                            className={`w-full mt-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:bg-gray-400 disabled:cursor-not-allowed`}
                            id="button-input"
                            type='submit'
                            disabled={isEnable}
                        >
                            Login
                        </button>

                    )}

                    <div className='mt-8 mb-8 flex justify-center items-center'>
                        <div onClick={handleGoogleLogin}
                            className="w-full flex justify-center items-center mt-6 cursor-pointer text-white bg-red-500 hover:bg-red-700 text-center  font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
                        > <FontAwesomeIcon icon={faGoogle} className='mr-4'></FontAwesomeIcon>
                            Sign in with Google
                        </div>
                    </div>

                    <div className="text-center mt-2">
                        <Link to="/auth/register" className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
                            Dont have Account?, Register
                        </Link>
                    </div>
                    <div className="text-center">
                        <Link to="/auth/forget-password" className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
                            Forgot Password?
                        </Link>
                    </div>
                </form>
                <p className="text-center text-gray-500 text-xs">
                    &copy; {(new Date().getFullYear())} Trafalines Community
                </p>
            </div>
        </div>
    );
}


