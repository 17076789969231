import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { faGripVertical, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class UserCardPage extends Component{
    render() {
        const { t } = this.props
        return (
            <div className="w-full lg:w-2/3 xl:w-2/5 pt-32 lg:pt-16 px-2">

            </div>
        );
    }
}

export default withTranslation() (UserCardPage)