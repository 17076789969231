import { fas } from "@fortawesome/free-solid-svg-icons";
import {
    REQUEST_RETRIEVE_ALL_POSTS,
    RETRIEVE_ALL_POSTS_SUCCESS,
    RETRIEVE_ALL_POSTS_FAIL,

    REQUEST_RETRIEVE_POPULAR_POSTS,
    RETRIEVE_POPULAR_POSTS_SUCCESS,
    RETRIEVE_POPULAR_POSTS_FAIL,

    REQUEST_RETRIEVE_TVS_POSTS,
    RETRIEVE_TVS_POSTS_SUCCESS,
    RETRIEVE_TVS_POSTS_FAIL,

    REQUEST_RETRIEVE_VIDEOS_POSTS,
    RETRIEVE_VIDEOS_POSTS_SUCCESS,
    RETRIEVE_VIDEOS_POSTS_FAIL,

    REQUEST_RETRIEVE_BG_POSTS,
    RETRIEVE_BG_POSTS_SUCCESS,
    RETRIEVE_BG_POSTS_FAIL,

    REQUEST_RETRIEVE_LINK_POSTS,
    RETRIEVE_LINK_POSTS_SUCCESS,
    RETRIEVE_LINK_POSTS_FAIL,

    REQUEST_RETRIEVE_USERS_POSTS,
    RETRIEVE_USERS_POSTS_SUCCESS,
    RETRIEVE_USERS_POSTS_FAIL,
    REQUEST_RETRIEVE_POSTS,
    RETRIEVE_POSTS_SUCCESS,
    RETRIEVE_POSTS_FAIL,
    REQUEST_RETRIEVE_LIKES_POSTS,
    RETRIEVE_LIKES_POSTS_SUCCESS,
    RETRIEVE_LIKES_POSTS_FAIL,
    REQUEST_RETRIEVE_COMMENTS_POSTS,
    RETRIEVE_COMMENTS_POSTS_SUCCESS,
    RETRIEVE_COMMENTS_POSTS_FAIL,
    REQUEST_RETRIEVE_LIKES_COMMENTS_POSTS,
    RETRIEVE_LIKES_COMMENTS_POSTS_SUCCESS,
    RETRIEVE_LIKES_COMMENTS_POSTS_FAIL,
    REQUEST_RETRIEVE_LIKES_REPLY_COMMENTS_POSTS,
    RETRIEVE_LIKES_REPLY_COMMENTS_POSTS_SUCCESS,
    RETRIEVE_LIKES_REPLY_COMMENTS_POSTS_FAIL,
    REQUEST_CREATE_POSTS_TEKS,
    CREATE_POSTS_TEKS_SUCCESS,
    CREATE_POSTS_TEKS_FAIL,
    REQUEST_CREATE_POSTS_BACKGROUND,
    CREATE_POSTS_BACKGROUND_SUCCESS,
    CREATE_POSTS_BACKGROUND_FAIL,
    REQUEST_CREATE_POSTS_IMAGES,
    CREATE_POSTS_IMAGES_SUCCESS,
    CREATE_POSTS_IMAGES_FAIL,
    REQUEST_CREATE_POSTS_VIDEOS,
    CREATE_POSTS_VIDEOS_SUCCESS,
    CREATE_POSTS_VIDEOS_FAIL,
    REQUEST_CREATE_POSTS_LINK,
    CREATE_POSTS_LINK_SUCCESS,
    CREATE_POSTS_LINK_FAIL,
    REQUEST_CREATE_POSTS_TVS,
    CREATE_POSTS_TVS_SUCCESS,
    CREATE_POSTS_TVS_FAIL,
    REQUEST_CREATE_LIKES_POSTS,
    CREATE_LIKES_POSTS_SUCCESS,
    CREATE_LIKES_POSTS_FAIL,
    REQUEST_CREATE_COMMENTS_POSTS_TEKS,
    CREATE_COMMENTS_POSTS_TEKS_SUCCESS,
    CREATE_COMMENTS_POSTS_TEKS_FAIL,
    REQUEST_CREATE_COMMENTS_POSTS_STICKER,
    CREATE_COMMENTS_POSTS_STICKER_SUCCESS,
    CREATE_COMMENTS_POSTS_STICKER_FAIL,
    REQUEST_CREATE_REPLY_COMMENTS_POSTS_TEKS,
    CREATE_REPLY_COMMENTS_POSTS_TEKS_SUCCESS,
    CREATE_REPLY_COMMENTS_POSTS_TEKS_FAIL,
    REQUEST_CREATE_REPLY_COMMENTS_POSTS_STICKER,
    CREATE_REPLY_COMMENTS_POSTS_STICKER_SUCCESS,
    CREATE_REPLY_COMMENTS_POSTS_STICKER_FAIL,
    REQUEST_CREATE_LIKES_COMMENTS_POSTS,
    CREATE_LIKES_COMMENTS_POSTS_SUCCESS,
    CREATE_LIKES_COMMENTS_POSTS_FAIL,
    REQUEST_CREATE_LIKES_REPLY_COMMENTS_POSTS,
    CREATE_LIKES_REPLY_COMMENTS_POSTS_SUCCESS,
    CREATE_LIKES_REPLY_COMMENTS_POSTS_FAIL,
    REQUEST_CREATE_REPORT_POSTS,
    CREATE_REPORT_POSTS_SUCCESS,
    CREATE_REPORT_POSTS_FAIL,
    REQUEST_CREATE_SAVE_POSTS,
    CREATE_SAVE_POSTS_SUCCESS,
    CREATE_SAVE_POSTS_FAIL,
    REQUEST_UPDATE_POSTS_TEKS,
    UPDATE_POSTS_TEKS_SUCCESS,
    UPDATE_POSTS_TEKS_FAIL,
    REQUEST_UPDATE_POSTS_BACKGROUND,
    UPDATE_POSTS_BACKGROUND_SUCCESS,
    UPDATE_POSTS_BACKGROUND_FAIL,
    REQUEST_UPDATE_POSTS_IMAGES,
    UPDATE_POSTS_IMAGES_SUCCESS,
    UPDATE_POSTS_IMAGES_FAIL,
    REQUEST_UPDATE_POSTS_VIDEOS,
    UPDATE_POSTS_VIDEOS_SUCCESS,
    UPDATE_POSTS_VIDEOS_FAIL,
    REQUEST_UPDATE_POSTS_LINK,
    UPDATE_POSTS_LINK_SUCCESS,
    UPDATE_POSTS_LINK_FAIL,
    REQUEST_UPDATE_POSTS_TVS,
    UPDATE_POSTS_TVS_SUCCESS,
    UPDATE_POSTS_TVS_FAIL,
    REQUEST_DELETE_POSTS,
    DELETE_POSTS_SUCCESS,
    DELETE_POSTS_FAIL,
    REQUEST_DELETE_COMMENTS_POSTS,
    DELETE_COMMENTS_POSTS_SUCCESS,
    DELETE_COMMENTS_POSTS_FAIL,
    REQUEST_DELETE_REPLY_COMMENTS_POSTS,
    DELETE_REPLY_COMMENTS_POSTS_SUCCESS,
    DELETE_REPLY_COMMENTS_POSTS_FAIL,
} from "../../../actions/posts/TypesPosts";

const initialState = {
    //post home
    isLoadingListPost: false,
    listPosts: [],
    hasMoreDataPost: true,
    loadingMoreDataPost: false,

    //popular
    isLoadingListPopularPost: false,
    listPopularPosts: [],
    hasMoreDataPopularPost: true,
    loadingMoreDataPopularPost: false,

    //tvs
    isLoadingListTvsPost: false,
    listTvsPosts: [],
    hasMoreDataTvsPost: true,
    loadingMoreDataTvsPost: false,

    //videos
    isLoadingListVideosPost: false,
    listVideosPosts: [],
    hasMoreDataVideosPost: true,
    loadingMoreDataVideosPost: false,

    //bg
    isLoadingListBgPost: false,
    listBgPosts: [],
    hasMoreDataBgPost: true,
    loadingMoreDataBgPost: false,

    //link
    isLoadingListLinkPost: false,
    listLinkPosts: [],
    hasMoreDataLinkPost: true,
    loadingMoreDataLinkPost: false,

    detailsPost: null,
    isLoading: false,

    listLikesPosts: [],
    listCommentsPosts: [],
    listLikesCommentsPosts: [],
    listLikesReplyCommentsPosts: [],
};

function postsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case REQUEST_RETRIEVE_ALL_POSTS:
            return {
                ...state,
                isLoadingListPost: true,
                loadingMoreDataPost: true,
            }
        case RETRIEVE_ALL_POSTS_SUCCESS:
            return {
                ...state,
                isLoadingListPost: false,
                loadingMoreDataPost: false,
                listPosts: state.listPosts.concat(payload.data),
                hasMoreDataPost: payload.hasMore,
            };
        case RETRIEVE_ALL_POSTS_FAIL:
            return {
                ...state,
                isLoadMorePosts: false,
                isLoadingListPost: false,
            }
        case REQUEST_RETRIEVE_POPULAR_POSTS:
            return {
                ...state,
                isLoadingListPopularPost: true,
                loadingMoreDataPopularPost: true
            }
        case RETRIEVE_POPULAR_POSTS_SUCCESS:
            return {
                ...state,
                listPopularPosts: state.listPopularPosts.concat(payload.data),
                isLoadingListPopularPost: false,
                loadingMoreDataPopularPost: false,
                hasMoreDataPopularPost: payload.hasMore
            };
        case RETRIEVE_POPULAR_POSTS_FAIL:
            return {
                ...state,
                isLoadingListPopularPost: false,
                loadingMoreDataPopularPost: false
            }
        case REQUEST_RETRIEVE_TVS_POSTS:
            return {
                ...state,
                isLoadingListTvsPost: true,
                loadingMoreDataTvsPost: true
            }
        case RETRIEVE_TVS_POSTS_SUCCESS:
            return {
                ...state,
                listTvsPosts: state.listTvsPosts.concat(payload.data),
                isLoadingListTvsPost: false,
                loadingMoreDataTvsPost: false,
                hasMoreDataTvsPost: payload.hasMore
            };
        case RETRIEVE_TVS_POSTS_FAIL:
            return {
                ...state,
                isLoadingListTvsPost: false,
                loadingMoreDataTvsPost: false
            }
        case REQUEST_RETRIEVE_VIDEOS_POSTS:
            return {
                ...state,
                isLoadingListVideosPost: true,
                loadingMoreDataVideosPost: true,
            }
        case RETRIEVE_VIDEOS_POSTS_SUCCESS:
            return {
                ...state,
                listVideosPosts: state.listVideosPosts.concat(payload.data),
                isLoadingListVideosPost: false,
                loadingMoreDataVideosPost: false,
                hasMoreDataVideosPost: payload.hasMore
            };
        case RETRIEVE_VIDEOS_POSTS_FAIL:
            return {
                ...state,
                isLoadingListVideosPost: false,
                loadingMoreDataVideosPost: false
            }
        case REQUEST_RETRIEVE_BG_POSTS:
            return {
                ...state,
                isLoadingListBgPost: true,
                loadingMoreDataBgPost: true,
            }
        case RETRIEVE_BG_POSTS_SUCCESS:
            return {
                ...state,
                listBgPosts: state.listBgPosts.concat(payload.data),
                isLoadingListBgPost: false,
                loadingMoreDataBgPost: false,
                hasMoreDataBgPost: payload.hasMore
            };
        case RETRIEVE_BG_POSTS_FAIL:
            return {
                ...state,
                isLoadingListBgPost: false,
                loadingMoreDataBgPost: false,
            }

        case REQUEST_RETRIEVE_LINK_POSTS:
            return {
                ...state,
                isLoadingListLinkPost: true,
                loadingMoreDataLinkPost: true
            }
        case RETRIEVE_LINK_POSTS_SUCCESS:
            return {
                ...state,
                listLinkPosts: state.listLinkPosts.concat(payload.data),
                isLoadingListLinkPost: false,
                loadingMoreDataLinkPost: false,
                hasMoreDataLinkPost: payload.hasMore,
            };
        case RETRIEVE_LINK_POSTS_FAIL:
            return {
                ...state,
                isLoadingListLinkPost: false,
                loadingMoreDataLinkPost: false
            }
        case REQUEST_RETRIEVE_USERS_POSTS:
            return {
                ...state,
                isLoading: true,
            }
        case RETRIEVE_USERS_POSTS_SUCCESS:
            return {
                ...state,
                listPosts: payload,
                isLoading: false,
            }
        case RETRIEVE_USERS_POSTS_FAIL:
            return {
                ...state,
                isLoading: false
            }
        case REQUEST_RETRIEVE_POSTS:
            return {
                ...state,
                isLoading: true,
            }
        case RETRIEVE_POSTS_SUCCESS:
            return {
                ...state,
                detailsPost: payload,
                isLoading: false,
            }
        case RETRIEVE_POSTS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case REQUEST_RETRIEVE_LIKES_POSTS:
            return {
                ...state,
                isLoading: true,
            }
        case RETRIEVE_LIKES_POSTS_SUCCESS:
            return {
                ...state,
                listLikesPosts: payload,
                isLoading: false,
            }
        case RETRIEVE_LIKES_POSTS_FAIL:
            return {
                ...state,
                isLoading: false
            }
        case REQUEST_RETRIEVE_COMMENTS_POSTS:
            return {
                ...state,
                isLoading: true,
            }
        case RETRIEVE_COMMENTS_POSTS_SUCCESS:
            return {
                ...state,
                listCommentsPosts: payload,
                isLoading: false,
            }
        case RETRIEVE_COMMENTS_POSTS_FAIL:
            return {
                ...state,
                isLoading: false
            }
        case REQUEST_RETRIEVE_LIKES_COMMENTS_POSTS:
            return {
                ...state,
                isLoading: true
            }
        case RETRIEVE_LIKES_COMMENTS_POSTS_SUCCESS:
            return {
                ...state,
                listLikesCommentsPosts: payload,
                isLoading: false
            }
        case RETRIEVE_LIKES_COMMENTS_POSTS_FAIL:
            return {
                ...state,
                isLoading: false
            }
        case REQUEST_RETRIEVE_LIKES_REPLY_COMMENTS_POSTS:
            return {
                ...state,
                isLoading: true
            }
        case RETRIEVE_LIKES_REPLY_COMMENTS_POSTS_SUCCESS:
            return {
                ...state,
                listLikesReplyCommentsPosts: payload,
                isLoading: false,
            }
        case RETRIEVE_LIKES_REPLY_COMMENTS_POSTS_FAIL:
            return {
                ...state,
                isLoading: false
            }
        case REQUEST_CREATE_POSTS_TEKS:
            return {
                ...state
            }
        case CREATE_POSTS_TEKS_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_POSTS_TEKS_FAIL:
            return {
                ...state
            }
        case REQUEST_CREATE_POSTS_BACKGROUND:
            return {
                ...state
            }
        case CREATE_POSTS_BACKGROUND_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_POSTS_BACKGROUND_FAIL:
            return {
                ...state
            }
        case REQUEST_CREATE_POSTS_IMAGES:
            return {
                ...state,
            }
        case CREATE_POSTS_IMAGES_SUCCESS:
            return {
                ...state,
                payload
            };
        case CREATE_POSTS_IMAGES_FAIL:
            return {
                ...state
            }
        case REQUEST_CREATE_POSTS_VIDEOS:
            return {
                ...state,
            }
        case CREATE_POSTS_VIDEOS_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_POSTS_VIDEOS_FAIL:
            return {
                ...state
            }
        case REQUEST_CREATE_POSTS_LINK:
            return {
                ...state,
            }
        case CREATE_POSTS_LINK_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_POSTS_LINK_FAIL:
            return {
                ...state,
            }
        case REQUEST_CREATE_POSTS_TVS:
            return {
                ...state,
            }
        case CREATE_POSTS_TVS_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_POSTS_TVS_FAIL:
            return {
                ...state,
            }
        case REQUEST_CREATE_LIKES_POSTS:
            return {
                ...state,
            }
        case CREATE_LIKES_POSTS_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_LIKES_POSTS_FAIL:
            return {
                ...state,
            }
        case REQUEST_CREATE_COMMENTS_POSTS_TEKS:
            return {
                ...state,
            }
        case CREATE_COMMENTS_POSTS_TEKS_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_COMMENTS_POSTS_TEKS_FAIL:
            return {
                ...state,
            }
        case REQUEST_CREATE_COMMENTS_POSTS_STICKER:
            return {
                ...state,
            }
        case CREATE_COMMENTS_POSTS_STICKER_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_COMMENTS_POSTS_STICKER_FAIL:
            return {
                ...state
            }
        case REQUEST_CREATE_REPLY_COMMENTS_POSTS_TEKS:
            return {
                ...state,
            }
        case CREATE_REPLY_COMMENTS_POSTS_TEKS_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_REPLY_COMMENTS_POSTS_TEKS_FAIL:
            return {
                ...state,
            }
        case REQUEST_CREATE_REPLY_COMMENTS_POSTS_STICKER:
            return {
                ...state,
            }
        case CREATE_REPLY_COMMENTS_POSTS_STICKER_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_REPLY_COMMENTS_POSTS_STICKER_FAIL:
            return {
                ...state
            }
        case REQUEST_CREATE_LIKES_COMMENTS_POSTS:
            return {
                ...state
            }
        case CREATE_LIKES_COMMENTS_POSTS_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_LIKES_COMMENTS_POSTS_FAIL:
            return {
                ...state,
            }
        case REQUEST_CREATE_LIKES_REPLY_COMMENTS_POSTS:
            return {
                ...state,
            }
        case CREATE_LIKES_REPLY_COMMENTS_POSTS_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_LIKES_REPLY_COMMENTS_POSTS_FAIL:
            return {
                ...state,
            }
        case REQUEST_CREATE_REPORT_POSTS:
            return {
                ...state,
            }
        case CREATE_REPORT_POSTS_SUCCESS:
            return {
                ...state,
                payload
            };
        case CREATE_REPORT_POSTS_FAIL:
            return {
                ...state,
            }
        case REQUEST_CREATE_SAVE_POSTS:
            return {
                ...state,
            }
        case CREATE_SAVE_POSTS_SUCCESS:
            return {
                ...state,
                payload
            }
        case CREATE_SAVE_POSTS_FAIL:
            return {
                ...state
            }
        case REQUEST_UPDATE_POSTS_TEKS:
            return {
                ...state,
            }
        case UPDATE_POSTS_TEKS_SUCCESS:
            return state.map((post) => {
                if (post.id === payload.id) {
                    return {
                        ...post,
                        ...payload,
                    }
                } else {
                    return post;
                }
            });
        case UPDATE_POSTS_TEKS_FAIL:
            return {
                ...state,
            }
        case REQUEST_UPDATE_POSTS_BACKGROUND:
            return {
                ...state,
            }
        case UPDATE_POSTS_BACKGROUND_SUCCESS:
            return state.map((post) => {
                if (post.id === payload.id) {
                    return {
                        ...post,
                        ...payload,
                    }
                } else {
                    return post;
                }
            });
        case UPDATE_POSTS_BACKGROUND_FAIL:
            return {
                ...state,
            }
        case REQUEST_UPDATE_POSTS_IMAGES:
            return {
                ...state,
            }
        case UPDATE_POSTS_IMAGES_SUCCESS:
            return state.map((post) => {
                if (post.id === payload.id) {
                    return {
                        ...post,
                        ...payload,
                    }
                } else {
                    return post;
                }
            });
        case UPDATE_POSTS_IMAGES_FAIL:
            return {
                ...state
            }
        case REQUEST_UPDATE_POSTS_VIDEOS:
            return {
                ...state,
            }
        case UPDATE_POSTS_VIDEOS_SUCCESS:
            return state.map((post) => {
                if (post.id === payload.id) {
                    return {
                        ...post,
                        ...payload,
                    }
                } else {
                    return post;
                }
            });
        case UPDATE_POSTS_VIDEOS_FAIL:
            return {
                ...state,
            }
        case REQUEST_UPDATE_POSTS_LINK:
            return {
                ...state,
            }
        case UPDATE_POSTS_LINK_SUCCESS:
            return state.map((post) => {
                if (post.id === payload.id) {
                    return {
                        ...post,
                        ...payload,
                    }
                } else {
                    return post;
                }
            });
        case UPDATE_POSTS_LINK_FAIL:
            return {
                ...state,
            }
        case REQUEST_UPDATE_POSTS_TVS:
            return {
                ...state,
            }
        case UPDATE_POSTS_TVS_SUCCESS:
            return state.map((post) => {
                if (post.id === payload.id) {
                    return {
                        ...post,
                        ...payload,
                    }
                } else {
                    return post;
                }
            });
        case UPDATE_POSTS_TVS_FAIL:
            return {
                ...state,
            }
        case REQUEST_DELETE_POSTS:
            return {
                ...state
            }
        case DELETE_POSTS_SUCCESS:
            return state.filter(({ id }) => id !== payload.id);
        case DELETE_POSTS_FAIL:
            return {
                ...state,
            }
        case REQUEST_DELETE_COMMENTS_POSTS:
            return {
                ...state,
            }
        case DELETE_COMMENTS_POSTS_SUCCESS:
            return state.filter(({ id }) => id !== payload.id);
        case DELETE_COMMENTS_POSTS_FAIL:
            return {
                ...state,
            }
        case REQUEST_DELETE_REPLY_COMMENTS_POSTS:
            return {
                ...state,
            }
        case DELETE_REPLY_COMMENTS_POSTS_SUCCESS:
            return state.filter(({ id }) => id !== payload.id);
        case DELETE_REPLY_COMMENTS_POSTS_FAIL:
            return {
                ...state,
            }
        default:
            return state;
    }
}

export default postsReducer;
