import {
    REQUEST_CREATE_STORIES_BACKGROUND,
    CREATE_STORIES_BACKGROUND_SUCCESS,
    CREATE_STORIES_BACKGROUND_FAIL,
    REQUEST_CREATE_STORIES_VIDEOS,
    CREATE_STORIES_VIDEOS_SUCCESS,
    CREATE_STORIES_VIDEOS_FAIL,
    REQUEST_CREATE_STORIES_IMAGES,
    CREATE_STORIES_IMAGES_SUCCESS,
    CREATE_STORIES_IMAGES_FAIL,
    REQUEST_DELETE_STORIES,
    DELETE_STORIES_SUCCESS,
    DELETE_STORIES_FAIL,
    RETRIEVE_ALL_STORIES_SUCCESS,
    RETRIEVE_ALL_STORIES_FAIL,
    REQUEST_RETRIEVE_ALL_STORIES,
    REQUEST_RETRIEVE_AUDIENCE_STORIES,
    RETRIEVE_AUDIENCE_STORIES_SUCCESS,
    RETRIEVE_AUDIENCE_STORIES_FAIL,
    RETRIEVE_USERS_STORIES_FAIL,
    RETRIEVE_USERS_STORIES_SUCCESS,
    REQUEST_RETRIEVE_USERS_STORIES
} from "./TypesStories";

import StoriesDataService from "../../services/stories/StoriesService";


export const retrieveAllStories = () => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_ALL_STORIES,
    });
    try {
        const res = await StoriesDataService.getAllStories();
        dispatch({
            type: RETRIEVE_ALL_STORIES_SUCCESS,
            payload: res.data.data,
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: RETRIEVE_ALL_STORIES_FAIL,
        });
    }
};

export const retrieveAudienceStories = (id) => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_AUDIENCE_STORIES,
    });
    try {
        const res = await StoriesDataService.getAudienceStories(id);
        dispatch({
            type: RETRIEVE_AUDIENCE_STORIES_SUCCESS,
            paylod: res.data.data,
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: RETRIEVE_AUDIENCE_STORIES_FAIL
        })
    }
};

export const retrieveUsersStories = (users_id) => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_USERS_STORIES,
    });
    try {
        const res = await StoriesDataService.getUsersStories(users_id);
        dispatch({
            type: RETRIEVE_USERS_STORIES_SUCCESS,
            paylod: res.data.data,
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: RETRIEVE_USERS_STORIES_FAIL,
        });
    }
};

export const craeteStoriesBackground = () => async (dispatch) => {
    dispatch({
        type: REQUEST_CREATE_STORIES_BACKGROUND,
    });
    try {
        const res = await StoriesDataService.createStoriesBackground({});
        dispatch({
            type: CREATE_STORIES_BACKGROUND_SUCCESS,
            paylod: res.data
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: CREATE_STORIES_BACKGROUND_FAIL,
        });
        return Promise.reject(err);
    }
};

export const craeteStoriesVideos = () => async (dispatch) => {
    dispatch({
        type: REQUEST_CREATE_STORIES_VIDEOS,
    });
    try {
        const res = await StoriesDataService.createStoriesVideos({});
        dispatch({
            type: CREATE_STORIES_VIDEOS_SUCCESS,
            paylod: res.data
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: CREATE_STORIES_VIDEOS_FAIL,
        });
        return Promise.reject(err);
    }
};

export const createStoriesImages = () => async (dispatch) => {
    dispatch({
        type: REQUEST_CREATE_STORIES_IMAGES,
    });
    try {
        const res = await StoriesDataService.createStoriesImages({});
        dispatch({
            type: CREATE_STORIES_IMAGES_SUCCESS,
            paylod: res.data
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: CREATE_STORIES_IMAGES_FAIL,
        });
        return Promise.reject(err);
    }
};

export const deleteStories = (id) => async (dispatch) => {
    dispatch({
        type: REQUEST_DELETE_STORIES,
    });
    try {
        await StoriesDataService.deleteStories(id);
        dispatch({
            type: DELETE_STORIES_SUCCESS,
            paylod: { id },
        });
    } catch (err) {
        dispatch({
            type: DELETE_STORIES_FAIL,
        });
        console.log(err);
    }
};