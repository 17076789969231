import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveContacts } from '../../../actions/home/home';
import ListContacts from './ListContacts';
import ContactsPlaceholder from '../../placeholder/home/ContactsPlaceholder';

export default function ContactWidget() {
    const { t, i18n } = useTranslation();
    const { isLoading, contactsList } = useSelector(state => state.home);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(retrieveContacts());
    }, [dispatch]);

    return (
        <div>
            {isLoading ?
                (
                    <ContactsPlaceholder />
                ) : (
                    <div>
                        <ListContacts contacts={contactsList} />
                        <div className=' px-1'>
                            <Link to="/home">
                                <button className="w-full bg-blue-400  dark:bg-dark-second hover:bg-blue-500  dark:hover:bg-dark-second text-white text-xs font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline">
                                    Lihat Selengkapnya</button>
                            </Link>
                        </div>
                    </div>
                )}
        </div>
    );

}