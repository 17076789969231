import React, { useEffect } from 'react';
import { faList, faPlus, faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveSuggestionPeople } from '../../../actions/home/home';
import ListSuggestionPeople from './ListSuggestionPeople';
import SuggestionPeoplePlaceholder from '../../placeholder/home/SuggestionPeoplePlaceholder';

export default function SuggestionPeople() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { isLoading, suggestionPeopleList } = useSelector(state => state.home);
    
    useEffect(() => {
        dispatch(retrieveSuggestionPeople());
    }, [dispatch]);

    return (
        <div>
            {isLoading ?
                (
                    <SuggestionPeoplePlaceholder />
                ) : (
                    <div>
                        <ListSuggestionPeople peoples={suggestionPeopleList} />
                        <Link to="/home">
                            <button className="w-full bg-blue-400 hover:bg-blue-500  dark:bg-dark-second text-white text-xs font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline">
                                Lihat Selengkapnya</button>
                        </Link >
                    </div>
                )
            }
        </div >
    );
}
