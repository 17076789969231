import React, { useRef, useEffect } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  FacebookMessengerShareButton,
  PinterestShareButton,
  VKShareButton,
  LineShareButton,
  TumblrShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
  FacebookMessengerIcon,
  PinterestIcon,
  VKIcon,
  LineIcon,
  TumblrIcon,
  EmailIcon,
} from 'react-share';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faLink } from '@fortawesome/free-solid-svg-icons';

const ShareComponent = ({ isShareDialogOpen, toggleShareDialog, shareUrl, shareTitle, copyToClipboard }) => {
  const dialogRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        toggleShareDialog();
      }
    };

    if (isShareDialogOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isShareDialogOpen, toggleShareDialog]);

  return (
    isShareDialogOpen && (
      <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-stretch md:justify-center items-end p-4">
        <div
          ref={dialogRef}
          className="w-full max-w-lg bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 shadow-lg rounded-lg p-4 animate-slide-up"
        >
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Share This Post</h3>
            <button
              onClick={toggleShareDialog}
              className="text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
            >
              <FontAwesomeIcon icon={faTimes} className="w-4 h-4" />
            </button>
          </div>
          <p className="text-gray-500 dark:text-gray-400 mb-4">Share via social media or copy the link.</p>
          <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 lg:grid-cols-3 mb-4">
            <FacebookShareButton
              url={shareUrl}
              quote={shareTitle}
              className="flex items-center gap-2 p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              <FacebookIcon size={32} round />
              <span className="dark:text-white">Facebook</span>
            </FacebookShareButton>
            <TwitterShareButton
              url={shareUrl}
              title={shareTitle}
              className="flex items-center gap-2 p-2 bg-blue-400 text-white rounded-md hover:bg-blue-500"
            >
              <TwitterIcon size={32} round />
              <span className="dark:text-white">X (Twitter)</span>
            </TwitterShareButton>
            <LinkedinShareButton
              url={shareUrl}
              title={shareTitle}
              className="flex items-center gap-2 p-2 bg-blue-700 text-white rounded-md hover:bg-blue-800"
            >
              <LinkedinIcon size={32} round />
              <span className="dark:text-white">LinkedIn</span>
            </LinkedinShareButton>
            <WhatsappShareButton
              url={shareUrl}
              title={shareTitle}
              className="flex items-center gap-2 p-2 bg-green-600 text-white rounded-md hover:bg-green-700"
            >
              <WhatsappIcon size={32} round />
              <span className="dark:text-white">WhatsApp</span>
            </WhatsappShareButton>
            <TelegramShareButton
              url={shareUrl}
              title={shareTitle}
              className="flex items-center gap-2 p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              <TelegramIcon size={32} round />
              <span className="dark:text-white">Telegram</span>
            </TelegramShareButton>
            <FacebookMessengerShareButton
              url={shareUrl}
              appId="your-facebook-app-id"
              className="flex items-center gap-2 p-2 bg-blue-800 text-white rounded-md hover:bg-blue-900"
            >
              <FacebookMessengerIcon size={32} round />
              <span className="dark:text-white">Messenger</span>
            </FacebookMessengerShareButton>
            <PinterestShareButton
              url={shareUrl}
              media={shareUrl} // Assuming the URL points to an image
              className="flex items-center gap-2 p-2 bg-red-600 text-white rounded-md hover:bg-red-700"
            >
              <PinterestIcon size={32} round />
              <span className="dark:text-white">Pinterest</span>
            </PinterestShareButton>
            <VKShareButton
              url={shareUrl}
              className="flex items-center gap-2 p-2 bg-vk-600 text-white rounded-md hover:bg-vk-700"
            >
              <VKIcon size={32} round />
              <span className="dark:text-white">VK</span>
            </VKShareButton>
            <LineShareButton
              url={shareUrl}
              className="flex items-center gap-2 p-2 bg-line-600 text-white rounded-md hover:bg-line-700"
            >
              <LineIcon size={32} round />
              <span className="dark:text-white">Line</span>
            </LineShareButton>
            <TumblrShareButton
              url={shareUrl}
              className="flex items-center gap-2 p-2 bg-tumblr-600 text-white rounded-md hover:bg-tumblr-700"
            >
              <TumblrIcon size={32} round />
              <span className="dark:text-white">Tumblr</span>
            </TumblrShareButton>
            <EmailShareButton
              url={shareUrl}
              subject={shareTitle}
              body={shareUrl}
              className="flex items-center gap-2 p-2 bg-email-600 text-white rounded-md hover:bg-email-700"
            >
              <EmailIcon size={32} round />
              <span className="dark:text-white">Email</span>
            </EmailShareButton>
            <button
              onClick={copyToClipboard}
              className="flex items-center gap-2 p-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 col-span-2 sm:col-span-1 lg:col-span-1"
            >
              <FontAwesomeIcon icon={faLink} className="w-4 h-4" />
              <span>Copy Link</span>
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default ShareComponent;
