import React from 'react';
import ContactWidget from '../util/Contact';
import SuggestionPeople from '../util/SuggestionPeople';

export default function RightSidebarWidget() {
    return (
        <div className="w-1/4 pt-16 h-full bg-white dark:bg-dark-main hidden xl:block fixed top-0 right-0 shadow">
            <div className="h-full overflow-y-scroll scrollbar-default space-y-8">
                <ContactWidget />
                <SuggestionPeople />
            </div>
        </div>
    );
}