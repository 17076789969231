import React, { useRef, useState } from 'react';

const ImageGallery = ({ posts }) => {
    if (posts.posts_types !== 'images') return null;

    const images = posts.posts_images;
    const totalImages = images.length;

    const renderImages = () => {
        switch (totalImages) {
            case 1:
                return (
                    <img
                        className="w-full h-[350px] object-cover bg-gray-300 dark:bg-gray-500"
                        src={images[0].files_large_thumbnail}
                        alt=""
                        onError={(e) => {
                            e.currentTarget.src = '/path/to/fallback-image.jpg';
                        }}
                    />
                );

            case 2:
                return (
                    <div className="flex space-x-1">
                        {images.map((img, index) => (
                            <img
                                key={index}
                                className="w-1/2 h-full object-cover"
                                src={img.files_large_thumbnail}
                                alt=""
                                onError={(e) => {
                                    e.currentTarget.src = '/path/to/fallback-image.jpg';
                                }}
                            />
                        ))}
                    </div>
                );

            case 3:
                return (
                    <div className="flex flex-col space-y-1">
                        <img
                            className="w-full h-1/2 object-cover max-h-[200px]"
                            src={images[0].files_large_thumbnail}
                            alt=""
                            onError={(e) => {
                                e.currentTarget.src = '/path/to/fallback-image.jpg';
                            }}
                        />
                        <div className="flex space-x-1 pr-1">
                            {images.slice(1).map((img, index) => (
                                <img
                                    key={index}
                                    className="w-1/2 h-full object-cover"
                                    src={img.files_large_thumbnail}
                                    alt=""
                                    onError={(e) => {
                                        e.currentTarget.src = '/path/to/fallback-image.jpg';
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                );

            case 4:
                return (
                    <div className="grid grid-cols-2 gap-1">
                        {images.map((img, index) => (
                            <img
                                key={index}
                                className="w-full h-full object-cover h-max-[300px] bg-gray-300 dark:bg-gray-700"
                                src={img.files_large_thumbnail}
                                alt=""
                                onError={(e) => {
                                    e.currentTarget.src = '/path/to/fallback-image.jpg';
                                }}
                            />
                        ))}
                    </div>
                );

            default:
                return (
                    <div className="grid grid-cols-2 gap-1 relative">
                        {images.slice(0, 4).map((img, index) => (
                            <div key={index} className="relative">
                                <img
                                    className="w-full h-full object-cover h-max-[300px] bg-gray-100 dark:bg-gray-700"
                                    src={img.files_large_thumbnail}
                                    alt=""
                                    onError={(e) => {
                                        e.currentTarget.src = '/path/to/fallback-image.jpg';
                                    }}
                                />
                                {index === 3 && (
                                    <div className="absolute inset-0 cursor-pointer flex items-center justify-center bg-black bg-opacity-50">
                                        <div className=' text-3xl text-white font-bold'>{totalImages - 4}+</div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                );
        }
    };

    return <div className="relative w-full h-full">{renderImages()}</div>;
};

export default ImageGallery;
