import React from "react";

function SuggestionPeoplePlaceholder() {
    const rows = Array(6).fill().map((_, i) => (
        <li>
            <div className="flex items-center justify-between space-x-4 p-2 hover:bg-gray-200 dark:hover:bg-dark-third dark:text-dark-txt rounded-lg cursor-pointer">
                <div className='flex'>
                    <div className="relative">
                        <div className="rounded-full w-8 h-8 bg-gray-100 dark:bg-gray-700" />
                    </div>
                    <div className='ml-4 mt-1'>
                        <div className="font-normal text-sm h-4 w-12 rounded bg-gray-100 dark:bg-gray-700"></div>
                    </div>
                </div>

                <div className='flex'>
                    <span><div className="w-full bg-gray-100 dark:bg-gray-700 rounded-full text-white font-bold py-2 px-2 focus:outline-none focus:shadow-outline">
                    </div></span>
                </div>
            </div>
        </li>
    ));
    return (
        <ul className="p-2 animate-pulse">
            <div className="flex justify-between items-center ml-2 mb-2 text-gray-500 dark:text-dark-txt">
                <span className="font-normal text-sm"><div className="h-5 w-12 bg-gray-100 dark:bg-gray-700 rounded"></div></span>
            </div>
            {rows}
            <div className=' px-1'>
                <button className="w-full bg-gray-100 dark:bg-gray-700 h-8 text-white text-xs font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"></button>
            </div>
        </ul>
    )
}

export default SuggestionPeoplePlaceholder;