import {
    REQUEST_RETRIEVE_ALL_STORIES,
    RETRIEVE_ALL_STORIES_SUCCESS,
    RETRIEVE_ALL_STORIES_FAIL,
    REQUEST_RETRIEVE_AUDIENCE_STORIES,
    RETRIEVE_AUDIENCE_STORIES_SUCCESS,
    RETRIEVE_AUDIENCE_STORIES_FAIL,
    REQUEST_RETRIEVE_USERS_STORIES,
    RETRIEVE_USERS_STORIES_SUCCESS,
    RETRIEVE_USERS_STORIES_FAIL,
    REQUEST_CREATE_STORIES_BACKGROUND,
    CREATE_STORIES_BACKGROUND_SUCCESS,
    CREATE_STORIES_BACKGROUND_FAIL,
    REQUEST_CREATE_STORIES_VIDEOS,
    CREATE_STORIES_VIDEOS_SUCCESS,
    CREATE_STORIES_VIDEOS_FAIL,
    REQUEST_CREATE_STORIES_IMAGES,
    CREATE_STORIES_IMAGES_SUCCESS,
    CREATE_STORIES_IMAGES_FAIL,
    REQUEST_DELETE_STORIES,
    DELETE_STORIES_SUCCESS,
    DELETE_STORIES_FAIL,
} from "../../../actions/stories/TypesStories";

const initialState = {
    listAllStories: [],
    listAudienceStories: [],
    listUsersStories: [],
    isLoading: false,
};

function storiesReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case REQUEST_RETRIEVE_ALL_STORIES:
            return {
                ...state,
                isLoading: true,
            }
        case RETRIEVE_ALL_STORIES_SUCCESS:
            return {
                ...state,
                listAllStories: payload,
                isLoading: false
            }
        case RETRIEVE_ALL_STORIES_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case REQUEST_RETRIEVE_AUDIENCE_STORIES:
            return {
                ...state,
                isLoading: true,
            }
        case RETRIEVE_AUDIENCE_STORIES_SUCCESS:
            return {
                ...state,
                listAudienceStories: payload,
                isLoading: false,
            };
        case RETRIEVE_AUDIENCE_STORIES_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case REQUEST_RETRIEVE_USERS_STORIES:
            return {
                ...state,
                isLoading: true,
            }
        case RETRIEVE_USERS_STORIES_SUCCESS:
            return {
                ...state,
                listUsersStories: payload,
                isLoading: false
            };
        case RETRIEVE_USERS_STORIES_FAIL:
            return {
                ...state,
                isLoading: false
            }
        case REQUEST_CREATE_STORIES_BACKGROUND:
            return {
                ...state,
            }
        case CREATE_STORIES_BACKGROUND_SUCCESS:
            return {
                ...state,
                payload
            };
        case CREATE_STORIES_BACKGROUND_FAIL:
            return {
                ...state,
            }
        case REQUEST_CREATE_STORIES_IMAGES:
            return {
                ...state,
            }
        case CREATE_STORIES_IMAGES_SUCCESS:
            return {
                ...state,
                payload
            };
        case CREATE_STORIES_IMAGES_FAIL:
            return {
                ...state,
            }
        case REQUEST_CREATE_STORIES_VIDEOS:
            return {
                ...state,
            }
        case CREATE_STORIES_VIDEOS_SUCCESS:
            return {
                ...state,
                payload
            };
        case CREATE_STORIES_VIDEOS_FAIL:
            return {
                ...state
            }
        case REQUEST_DELETE_STORIES:
            return {
                ...state,
            }
        case DELETE_STORIES_SUCCESS:
            return state.filter(({ id }) => id !== payload.id);
        case DELETE_STORIES_FAIL:
            return {
                ...state
            }
        default:
            return state;
    }
}

export default storiesReducer;