import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { faEdit, faHotel, faTicketAlt, faStore, faLink, faBookReader, faMagic, faStar, faTv, faPlay, faPallet, faUtensils, faHandsHelping, faShieldAlt, faCertificate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { retrieveCurrentUser } from '../../../actions/home/home';
import { useDispatch, useSelector } from 'react-redux';

export default function SideBarWidget() {

    const { isLoading, currentUser } = useSelector(state => state.home);
    const dispattch = useDispatch();

    useEffect(() => {
        dispattch(retrieveCurrentUser());
    }, [dispattch]);

    return (
        <div className="w-1/4 pt-14 h-full bg-white hidden xl:flex flex-col fixed top-0 left-0 dark:bg-dark-main overflow-y-scroll shadow">
            {
                currentUser && (

                    <>
                        <div className='relative'>
                            <div className='h-60 '>
                                {
                                    currentUser && currentUser.covers && (
                                        <img src={currentUser.covers.files_medium_thumbnail} alt="" className="w-full h-48  object-cover bg-gray-100 dark:bg-dark-main" />
                                    )
                                }
                            </div>
                            <div className=' absolute right-1 bottom-16'>
                                <div className="text-sm bg-gray-100  dark:bg-dark-third text-gray-800 dark:text-dark-txt grid place-items-center rounded-full p-3 cursor-pointer relative">
                                    <FontAwesomeIcon icon={faEdit} />
                                </div>
                            </div>
                            <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 translate-y-7'>
                                <div className='relative'>
                                    {
                                        currentUser && currentUser.photos && (
                                            <img src={currentUser.photos.files_small_thumbnail} alt="" className=" w-20  h-20 bg-gray-100 dark:bg-gray-700  object-cover rounded-full" />
                                        )
                                    }
                                    <div className=' absolute -right-1 -bottom-2'>
                                        <div className="text-sm bg-gray-100  dark:bg-dark-third text-gray-800 dark:text-dark-txt grid place-items-center rounded-full p-3 cursor-pointer relative">
                                            <FontAwesomeIcon icon={faEdit} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='py-4 text-center'>
                            <p className='text-gray-400 dark:text-dark-txt text-sm'>{currentUser.username}</p>
                            <p className='text-gray-400 dark:text-dark-txt text-sm'>{currentUser.name}</p>
                        </div>
                        <div className='flex flex-row'>
                            <Link to="/account/follower" className='w-full text-center p-2 mb-2 mr-1'>
                                <p className=' text-gray font-bold dark:text-dark-txt text-sm'>{currentUser.follower_count}</p>
                                <p className=' text-gray-600 dark:text-dark-txt text-sm'>Pengikut</p>
                            </Link>
                            <Link to="/account/following" className='w-full text-center p-2 mb-2'>
                                <p className=' text-gray font-bold dark:text-dark-txt text-sm'>{currentUser.following_count}</p>
                                <p className=' text-gray-600 dark:text-dark-txt text-sm'>Mengikuti</p>
                            </Link>
                            <Link to="/account/friend" className='w-full text-center p-2 mb-2'>
                                <p className=' text-gray font-bold dark:text-dark-txt text-sm'>{currentUser.friend_count}</p>
                                <p className=' text-gray-600 dark:text-dark-txt text-sm'>Teman</p>
                            </Link>
                        </div>
                    </>
                )
            }

            <ul className="pl-4 pt-2">
                {
                    currentUser && (
                        <li>
                            <Link to="/profile" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                                <img src={currentUser.photos.files_small_thumbnail} alt="Profile picture" className="w-8 h-8 object-cover rounded-full" />
                                <span className="font-semibold">{currentUser.name}</span>
                            </Link>
                        </li>
                    )
                }

                <li>
                    <Link to="/official" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <FontAwesomeIcon icon={faCertificate} className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">Official</span>
                    </Link>
                </li>

                <li>
                    <Link to="/accommodation" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <FontAwesomeIcon icon={faHotel} className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">Accommodation</span>
                    </Link>
                </li>

                <li>
                    <Link to="/restos" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <FontAwesomeIcon icon={faUtensils} className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">Restos</span>
                    </Link>
                </li>

                <li>
                    <Link to="/services" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <FontAwesomeIcon icon={faHandsHelping} className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">Services</span>
                    </Link>
                </li>

                <li>
                    <Link to="/marketplace" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <FontAwesomeIcon icon={faStore} className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">Marketplace</span>
                    </Link>
                </li>

                <li>
                    <Link to="/ticket" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <FontAwesomeIcon icon={faTicketAlt} className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">Ticket</span>
                    </Link>
                </li>

                <li>
                    <Link to="/blog" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <FontAwesomeIcon icon={faBookReader} className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">Blog</span>
                    </Link>
                </li>

                <li>
                    <Link to="/foryou" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <FontAwesomeIcon icon={faMagic} className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">For You</span>
                    </Link>
                </li>

                <li>
                    <Link to="/popular" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <FontAwesomeIcon icon={faStar} className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">Popular</span>
                    </Link>
                </li>

                {/* <li>
                    <Link to="/birthday" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <img src={Birthday} alt="Profile picture" className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">{t('label_birthday')}</span>
                    </Link>
                </li> */}

                <li>
                    <Link to="/tvs" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <FontAwesomeIcon icon={faTv} className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">TVS</span>

                    </Link>
                </li>
                <li>
                    <Link to="/watch" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <FontAwesomeIcon icon={faPlay} className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">Watch</span>
                    </Link>
                </li>
                <li>
                    <Link to="/background" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <FontAwesomeIcon icon={faPallet} className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">Background</span>
                    </Link>
                </li>
                <li>
                    <Link to="/link" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <FontAwesomeIcon icon={faLink} className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">Link</span>
                    </Link>
                </li>
                {/* <li>
                    <Link to="/events/home" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <img src={Events} alt="Profile picture" className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">{t('label_event')}</span>

                    </Link>
                </li>
                <li>
                    <Link to="/account/visitor-profile" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <img src={VisitorProfil} alt="Profile picture" className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">{t('title_visitor_profile')}</span>
                        <span className=' bg-red-500 rounded-full text-white text-xs text-center px-1'>9</span>
                    </Link>

                </li>
                <li>
                    <Link to="/account/love-profile" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <img src={LoveProfil} alt="Profile picture" className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">{t('title_love_profile')}</span>
                        <span className=' bg-red-500 rounded-full text-white text-xs text-center px-1'>9</span>
                    </Link>
                </li>
                <li>
                    <Link to="/account/match" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <img src={Match} alt="Profile picture" className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">{t('label_match')}</span>
                        <span className=' bg-red-500 rounded-full text-white text-xs text-center px-1'>9</span>
                    </Link>
                </li>
                <li>
                    <Link to="/online" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <img src={Online} alt="Profile picture" className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">{t('label_users_online')}</span>
                        <span className=' bg-red-500 rounded-full text-white text-xs text-center px-1'>9</span>
                    </Link>
                </li> */}
                {/* <li>
                    <Link to="/gifts" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <img src={Gifts} alt="Profile picture" className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">{t('label_gifts')}</span>
                    </Link>
                </li>
                <li>
                    <Link to="/account/friend" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <img src={Friends} alt="Profile picture" className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">{t('label_best_friend')}</span>
                    </Link>
                </li>
                <li>
                    <Link to="/moments" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <img src={Moments} alt="Profile picture" className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">{t('title_moments')}</span>
                    </Link>
                </li>
                <li>
                    <Link to="/saved/posts" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <img src={Saved} alt="Profile picture" className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">{t('label_saved')}</span>
                    </Link>
                </li> */}
                {/* <li>
                    <Link to="/group/home" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <img src={Group} alt="Profile picture" className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">Group</span>
                    </Link>
                </li>
                <li>
                    <Link to="/coins/index" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <img src={Coins} alt="Profile picture" className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">Coins</span>
                    </Link>
                </li>
                <li>
                    <Link to="/notes/home" className="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg transition-all dark:text-dark-txt dark:hover:bg-dark-third">
                        <img src={Notes} alt="Profile picture" className="w-8 h-8 rounded-full" />
                        <span className="font-semibold">Notes</span>
                    </Link>
                </li> */}
            </ul>
        </div>
    );

}