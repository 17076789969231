import React, { useState, useEffect } from 'react';
import AddStoryImg from '../../../assets/images/new/profile.jpg';
import { faList, faPlus, faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveAllStories } from '../../../actions/stories/StoriesActions';
import HomeStoriesPlaceholder from '../../placeholder/stories/HomeStoriesPlaceholder';

export default function StoriesWidget() {
  const { listAllStories, isLoading } = useSelector(state => state.stories);
  const dispatch = useDispatch();

  useEffect(() => {
    if (listAllStories.length === 0) {
      dispatch(retrieveAllStories());
    }
  }, [dispatch, listAllStories.length]);

  return (
    <div className="bg-white mt-4 shadow rounded-none xl:rounded-xl dark:bg-dark-second">
      <div className="md:flex">
        <div className="w-full p-0 px-0 py-2">
          {isLoading ? (
            <HomeStoriesPlaceholder />
          ) : (
            <ul className="flex space-x-2 overflow-x-scroll px-2 scrollbar-hide">
              <li className="flex flex-col items-center">
                <button data-modal-toggle="small-modal" className="flex items-center justify-center rounded-full object-cover bg-gradient-to-br from-yellow-200 via-red-500 to-pink-500 w-20 h-20">
                  <FontAwesomeIcon icon={faPlus} className="text-white" />
                </button>
                <span class="mb-0 mt-1 text-xs w-16 overflow-hidden overflow-ellipsis dark:text-dark-txt">Add Story</span>
              </li>

              {listAllStories && listAllStories.map((storie, index) => (
                <li className="flex flex-col items-center">
                  <button className="flex items-center justify-center rounded-full object-cover bg-gradient-to-br from-yellow-200 via-red-500 to-pink-500 w-20 h-20">
                    <img src={storie.profile_img_small} style={{ width: '74px', height: '74px' }} class="rounded-full object-cover border-4" />
                  </button>
                  <span class="mb-0 mt-1 text-xs w-16 overflow-hidden overflow-ellipsis dark:text-dark-txt text-center">{storie.username}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

    </div>
  );
}
