import {
    REQUEST_RETRIEVE_ALL_POSTS,
    RETRIEVE_ALL_POSTS_SUCCESS,
    RETRIEVE_ALL_POSTS_FAIL,

    REQUEST_RETRIEVE_POPULAR_POSTS,
    RETRIEVE_POPULAR_POSTS_SUCCESS,
    RETRIEVE_POPULAR_POSTS_FAIL,

    

    REQUEST_RETRIEVE_TVS_POSTS,
    RETRIEVE_TVS_POSTS_SUCCESS,
    RETRIEVE_TVS_POSTS_FAIL,

    REQUEST_RETRIEVE_VIDEOS_POSTS,
    RETRIEVE_VIDEOS_POSTS_SUCCESS,
    RETRIEVE_VIDEOS_POSTS_FAIL,

    REQUEST_RETRIEVE_BG_POSTS,
    RETRIEVE_BG_POSTS_SUCCESS,
    RETRIEVE_BG_POSTS_FAIL,

    REQUEST_RETRIEVE_LINK_POSTS,
    RETRIEVE_LINK_POSTS_SUCCESS,
    RETRIEVE_LINK_POSTS_FAIL,

    REQUEST_RETRIEVE_USERS_POSTS,
    RETRIEVE_USERS_POSTS_SUCCESS,
    RETRIEVE_USERS_POSTS_FAIL,
    REQUEST_RETRIEVE_POSTS,
    RETRIEVE_POSTS_SUCCESS,
    RETRIEVE_POSTS_FAIL,
    REQUEST_RETRIEVE_LIKES_POSTS,
    RETRIEVE_LIKES_POSTS_SUCCESS,
    RETRIEVE_LIKES_POSTS_FAIL,
    REQUEST_RETRIEVE_COMMENTS_POSTS,
    RETRIEVE_COMMENTS_POSTS_SUCCESS,
    RETRIEVE_COMMENTS_POSTS_FAIL,
    REQUEST_RETRIEVE_LIKES_COMMENTS_POSTS,
    RETRIEVE_LIKES_COMMENTS_POSTS_SUCCESS,
    RETRIEVE_LIKES_COMMENTS_POSTS_FAIL,
    REQUEST_RETRIEVE_LIKES_REPLY_COMMENTS_POSTS,
    RETRIEVE_LIKES_REPLY_COMMENTS_POSTS_SUCCESS,
    RETRIEVE_LIKES_REPLY_COMMENTS_POSTS_FAIL,
    REQUEST_CREATE_POSTS_TEKS,
    CREATE_POSTS_TEKS_SUCCESS,
    CREATE_POSTS_TEKS_FAIL,
    REQUEST_CREATE_POSTS_BACKGROUND,
    CREATE_POSTS_BACKGROUND_SUCCESS,
    CREATE_POSTS_BACKGROUND_FAIL,
    REQUEST_CREATE_POSTS_IMAGES,
    CREATE_POSTS_IMAGES_SUCCESS,
    CREATE_POSTS_IMAGES_FAIL,
    REQUEST_CREATE_POSTS_VIDEOS,
    CREATE_POSTS_VIDEOS_SUCCESS,
    CREATE_POSTS_VIDEOS_FAIL,
    REQUEST_CREATE_POSTS_LINK,
    CREATE_POSTS_LINK_SUCCESS,
    CREATE_POSTS_LINK_FAIL,
    REQUEST_CREATE_POSTS_TVS,
    CREATE_POSTS_TVS_SUCCESS,
    CREATE_POSTS_TVS_FAIL,
    REQUEST_CREATE_LIKES_POSTS,
    CREATE_LIKES_POSTS_SUCCESS,
    CREATE_LIKES_POSTS_FAIL,
    REQUEST_CREATE_COMMENTS_POSTS_TEKS,
    CREATE_COMMENTS_POSTS_TEKS_SUCCESS,
    CREATE_COMMENTS_POSTS_TEKS_FAIL,
    REQUEST_CREATE_COMMENTS_POSTS_STICKER,
    CREATE_COMMENTS_POSTS_STICKER_SUCCESS,
    CREATE_COMMENTS_POSTS_STICKER_FAIL,
    REQUEST_CREATE_REPLY_COMMENTS_POSTS_TEKS,
    CREATE_REPLY_COMMENTS_POSTS_TEKS_SUCCESS,
    CREATE_REPLY_COMMENTS_POSTS_TEKS_FAIL,
    REQUEST_CREATE_REPLY_COMMENTS_POSTS_STICKER,
    CREATE_REPLY_COMMENTS_POSTS_STICKER_SUCCESS,
    CREATE_REPLY_COMMENTS_POSTS_STICKER_FAIL,
    REQUEST_CREATE_LIKES_COMMENTS_POSTS,
    CREATE_LIKES_COMMENTS_POSTS_SUCCESS,
    CREATE_LIKES_COMMENTS_POSTS_FAIL,
    REQUEST_CREATE_LIKES_REPLY_COMMENTS_POSTS,
    CREATE_LIKES_REPLY_COMMENTS_POSTS_SUCCESS,
    CREATE_LIKES_REPLY_COMMENTS_POSTS_FAIL,
    REQUEST_CREATE_REPORT_POSTS,
    CREATE_REPORT_POSTS_SUCCESS,
    CREATE_REPORT_POSTS_FAIL,
    REQUEST_CREATE_SAVE_POSTS,
    CREATE_SAVE_POSTS_SUCCESS,
    CREATE_SAVE_POSTS_FAIL,
    REQUEST_UPDATE_POSTS_TEKS,
    UPDATE_POSTS_TEKS_SUCCESS,
    UPDATE_POSTS_TEKS_FAIL,
    REQUEST_UPDATE_POSTS_BACKGROUND,
    UPDATE_POSTS_BACKGROUND_SUCCESS,
    UPDATE_POSTS_BACKGROUND_FAIL,
    REQUEST_UPDATE_POSTS_IMAGES,
    UPDATE_POSTS_IMAGES_SUCCESS,
    UPDATE_POSTS_IMAGES_FAIL,
    REQUEST_UPDATE_POSTS_VIDEOS,
    UPDATE_POSTS_VIDEOS_SUCCESS,
    UPDATE_POSTS_VIDEOS_FAIL,
    REQUEST_UPDATE_POSTS_LINK,
    UPDATE_POSTS_LINK_SUCCESS,
    UPDATE_POSTS_LINK_FAIL,
    REQUEST_UPDATE_POSTS_TVS,
    UPDATE_POSTS_TVS_SUCCESS,
    UPDATE_POSTS_TVS_FAIL,
    REQUEST_DELETE_POSTS,
    DELETE_POSTS_SUCCESS,
    DELETE_POSTS_FAIL,
    REQUEST_DELETE_COMMENTS_POSTS,
    DELETE_COMMENTS_POSTS_SUCCESS,
    DELETE_COMMENTS_POSTS_FAIL,
    REQUEST_DELETE_REPLY_COMMENTS_POSTS,
    DELETE_REPLY_COMMENTS_POSTS_SUCCESS,
    DELETE_REPLY_COMMENTS_POSTS_FAIL,
} from "./TypesPosts";

import PostsDataService from "../../services/posts/PostsService.js";


export const retrieveAllPosts = (page = 1) => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_ALL_POSTS,
    });
    try {
        const res = await PostsDataService.getAllPosts(page);
        dispatch({
            type: RETRIEVE_ALL_POSTS_SUCCESS,
            payload: {
                data: res.data.data,
                page,
            },
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_ALL_POSTS_FAIL,
        });
        console.log(err);
    }
};

export const retrievePopularPosts = (page = 1) => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_POPULAR_POSTS,
    });
    try {
        const res = await PostsDataService.getPopularPosts(page);
        dispatch({
            type: RETRIEVE_POPULAR_POSTS_SUCCESS,
            payload: {
                data: res.data.data,
                page,
            },
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_POPULAR_POSTS_FAIL,
        });
        console.log(err);
    }
};

export const retrieveTvsPosts = (page = 1) => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_TVS_POSTS,
    });
    try {
        const res = await PostsDataService.getTvsPosts(page);
        dispatch({
            type: RETRIEVE_TVS_POSTS_SUCCESS,
            payload: {
                data: res.data.data,
                page
            },
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_TVS_POSTS_FAIL,
        });
        console.log(err);
    }
};

export const retrieveVideosPosts = (page = 1) => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_VIDEOS_POSTS,
    });
    try {
        const res = await PostsDataService.getVideosPosts(page);
        dispatch({
            type: RETRIEVE_VIDEOS_POSTS_SUCCESS,
            payload: {
                data: res.data.data,
                page
            },
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_VIDEOS_POSTS_FAIL,
        });
        console.log(err);
    }
};

export const retrieveBgPosts = (page=1) => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_BG_POSTS,
    });
    try {
        const res = await PostsDataService.getBgPosts(page);
        dispatch({
            type: RETRIEVE_BG_POSTS_SUCCESS,
            payload: {
                data:res.data.data,
                page
            },
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_BG_POSTS_FAIL,
        });
        console.log(err);
    }
};

export const retrieveLinkPosts = (page = 1) => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_LINK_POSTS,
    });
    try {
        const res = await PostsDataService.getLinkPosts(page);
        dispatch({
            type: RETRIEVE_LINK_POSTS_SUCCESS,
            payload: {
                data: res.data.data,
                page
            },
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_LINK_POSTS_FAIL,
        });
        console.log(err);
    }
};

export const retrieveUsersPosts = (users_id) => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_USERS_POSTS,
    });
    try {
        const res = await PostsDataService.getUsersPosts(users_id);
        dispatch({
            type: RETRIEVE_USERS_POSTS_SUCCESS,
            payload: res.data.data,
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_USERS_POSTS_FAIL
        });
        console.log(err);
    }
};

export const retrievePosts = (id) => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_POSTS,
    });
    try {
        const res = await PostsDataService.getPosts(id);
        dispatch({
            type: RETRIEVE_POSTS_SUCCESS,
            payload: res.data.data,
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_POSTS_FAIL,
        });
        console.log(err);
    }
};

export const retrieveLikesPosts = (id) => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_LIKES_POSTS,
    });
    try {
        const res = await PostsDataService.getLikesPosts(id);
        dispatch({
            type: RETRIEVE_LIKES_POSTS_SUCCESS,
            payload: res.data.data,
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_LIKES_POSTS_FAIL,
        });
        console.log(err);
    }
};

export const retrieveCommentsPosts = (id) => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_COMMENTS_POSTS
    });
    try {
        const res = await PostsDataService.getCommentsPosts(id);
        dispatch({
            type: RETRIEVE_COMMENTS_POSTS_SUCCESS,
            payload: res.data.data,
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_COMMENTS_POSTS_FAIL,
        });
        console.log(err);
    }
};

export const retrieveLikesCommentsPosts = (id) => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_LIKES_COMMENTS_POSTS,
    });
    try {
        const res = await PostsDataService.getLikesCommentsPosts();
        dispatch({
            type: RETRIEVE_LIKES_COMMENTS_POSTS_SUCCESS,
            payload: res.data.data,
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_LIKES_COMMENTS_POSTS_FAIL,
        });
        console.log(err);
    }
};

export const retrieveLikesReplyCommentsPosts = () => async (dispatch) => {
    dispatch({
        type: REQUEST_RETRIEVE_LIKES_REPLY_COMMENTS_POSTS,
    });
    try {
        const res = await PostsDataService.getLikesReplyCommentsPosts();
        dispatch({
            type: RETRIEVE_LIKES_REPLY_COMMENTS_POSTS_SUCCESS,
            payload: res.data.data,
        });
    } catch (err) {
        dispatch({
            type: RETRIEVE_LIKES_REPLY_COMMENTS_POSTS_FAIL,
        });
        console.log(err);
    }
};

export const createPostsTeks = () => async (dispatch) => {
    dispatch({
        type: REQUEST_CREATE_POSTS_TEKS,
    });
    try {
        const res = await PostsDataService.createPostsTeks({});
        dispatch({
            type: CREATE_POSTS_TEKS_SUCCESS,
            payload: res.data
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: CREATE_POSTS_TEKS_FAIL,
        });
        return Promise.reject(err);
    }
};

export const createPostsBackground = () => async (dispatch) => {
    dispatch({
        type: REQUEST_CREATE_POSTS_BACKGROUND,
    });
    try {
        const res = await PostsDataService.createPostsBackground({});
        dispatch({
            type: CREATE_POSTS_BACKGROUND_SUCCESS,
            payload: res.data
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: CREATE_POSTS_BACKGROUND_FAIL,
        });
        return Promise.reject(err);
    }
};

export const createPostsImages = () => async (dispatch) => {
    dispatch({
        type: REQUEST_CREATE_POSTS_IMAGES,
    });
    try {
        const res = await PostsDataService.createPostsImages({});
        dispatch({
            type: CREATE_POSTS_IMAGES_SUCCESS,
            payload: res.data
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: CREATE_POSTS_IMAGES_FAIL,
        });
        return Promise.reject(err);
    }
};

export const createPostsVideos = () => async (dispatch) => {
    dispatch({
        type: REQUEST_CREATE_POSTS_VIDEOS,
    });
    try {
        const res = await PostsDataService.createPostsVideos({});
        dispatch({
            type: CREATE_POSTS_VIDEOS_SUCCESS,
            payload: res.data
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: CREATE_POSTS_VIDEOS_FAIL,
        });
        return Promise.reject(err);
    }
};

export const createPostsLink = () => async (dispatch) => {
    dispatch({
        type: REQUEST_CREATE_POSTS_LINK,
    });
    try {
        const res = await PostsDataService.createPostsLink({});
        dispatch({
            type: CREATE_POSTS_LINK_SUCCESS,
            payload: res.data
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: CREATE_POSTS_LINK_FAIL,
        });
        return Promise.reject(err);
    }
};

export const createPostsTvs = () => async (dispatch) => {
    dispatch({
        type: REQUEST_CREATE_POSTS_TVS,
    });
    try {
        const res = await PostsDataService.createPostsTvs({});
        dispatch({
            type: CREATE_POSTS_TVS_SUCCESS,
            payload: res.data
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: CREATE_POSTS_TVS_FAIL,
        });
        return Promise.reject(err);
    }
};

export const createLikesPosts = () => async (dispatch) => {
    dispatch({
        type: REQUEST_CREATE_LIKES_POSTS,
    });
    try {
        const res = await PostsDataService.createLikesPosts({});
        dispatch({
            type: CREATE_LIKES_POSTS_SUCCESS,
            payload: res.data
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: CREATE_LIKES_POSTS_FAIL,
        });
        return Promise.reject(err);
    }
};

export const createCommentsPostsTeks = () => async (dispatch) => {
    dispatch({
        type: REQUEST_CREATE_COMMENTS_POSTS_TEKS,
    });
    try {
        const res = await PostsDataService.createCommentsPostsTeks({});
        dispatch({
            type: CREATE_COMMENTS_POSTS_TEKS_SUCCESS,
            payload: res.data
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: CREATE_COMMENTS_POSTS_TEKS_FAIL,
        });
        return Promise.reject(err);
    }
};

export const createCommentsPostsSticker = () => async (dispatch) => {
    dispatch({
        type: REQUEST_CREATE_COMMENTS_POSTS_STICKER,
    });
    try {
        const res = await PostsDataService.createCommentsPostsSticker({});
        dispatch({
            type: CREATE_COMMENTS_POSTS_STICKER_SUCCESS,
            payload: res.data
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: CREATE_COMMENTS_POSTS_STICKER_FAIL,
        })
        return Promise.reject(err);
    }
};

export const createReplyCommentsPostsTeks = () => async (dispatch) => {
    dispatch({
        type: REQUEST_CREATE_REPLY_COMMENTS_POSTS_TEKS,
    });
    try {
        const res = await PostsDataService.createReplyCommentsPostsTeks({});
        dispatch({
            type: CREATE_REPLY_COMMENTS_POSTS_TEKS_SUCCESS,
            payload: res.data
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: CREATE_REPLY_COMMENTS_POSTS_TEKS_FAIL,
        });
        return Promise.reject(err);
    }
};

export const createReplyCommentsPostsSticker = () => async (dispatch) => {
    dispatch({
        type: REQUEST_CREATE_REPLY_COMMENTS_POSTS_STICKER,
    });
    try {
        const res = await PostsDataService.createReplyCommentsPostsSticker({});
        dispatch({
            type: CREATE_REPLY_COMMENTS_POSTS_STICKER_SUCCESS,
            payload: res.data
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: CREATE_REPLY_COMMENTS_POSTS_STICKER_FAIL,
        });
        return Promise.reject(err);
    }
};

export const createLikesCommentsPosts = () => async (dispatch) => {
    dispatch({
        type: REQUEST_CREATE_LIKES_COMMENTS_POSTS,
    });
    try {
        const res = await PostsDataService.createLikesCommentsPosts({});
        dispatch({
            type: CREATE_LIKES_COMMENTS_POSTS_SUCCESS,
            payload: res.data
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: CREATE_LIKES_COMMENTS_POSTS_FAIL,
        });
        return Promise.reject(err);
    }
};

export const createLikesReplyCommentsPosts = () => async (dispatch) => {
    dispatch({
        type: REQUEST_CREATE_LIKES_REPLY_COMMENTS_POSTS,
    });
    try {
        const res = await PostsDataService.createLikesReplyCommentsPosts({});
        dispatch({
            type: CREATE_LIKES_REPLY_COMMENTS_POSTS_SUCCESS,
            payload: res.data
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: CREATE_LIKES_REPLY_COMMENTS_POSTS_FAIL,
        });
        return Promise.reject(err);
    }
};

export const createReportPosts = () => async (dispatch) => {
    dispatch({
        type: REQUEST_CREATE_REPORT_POSTS,
    });
    try {
        const res = await PostsDataService.createReportPosts({});
        dispatch({
            type: CREATE_REPORT_POSTS_SUCCESS,
            payload: res.data
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: CREATE_REPORT_POSTS_FAIL,
        });
        return Promise.reject(err);
    }
};

export const createSavePosts = () => async (dispatch) => {
    dispatch({
        type: REQUEST_CREATE_SAVE_POSTS,
    });
    try {
        const res = await PostsDataService.createSavePosts({});
        dispatch({
            type: CREATE_SAVE_POSTS_SUCCESS,
            payload: res.data
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: CREATE_SAVE_POSTS_FAIL,
        });
        return Promise.reject(err);
    }
};

export const updatePostsTeks = (id, data) => async (dispatch) => {
    dispatch({
        type: REQUEST_UPDATE_POSTS_TEKS,
    });
    try {
        const res = await PostsDataService.updatePostsTeks(id, data);
        dispatch({
            type: UPDATE_POSTS_TEKS_SUCCESS,
            payload: data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: UPDATE_POSTS_TEKS_FAIL,
        });
        return Promise.reject(err);
    }
};

export const updatePostsBackground = (id, data) => async (dispatch) => {
    dispatch({
        type: REQUEST_UPDATE_POSTS_BACKGROUND,
    });
    try {
        const res = await PostsDataService.updatePostsBackground(id, data);
        dispatch({
            type: UPDATE_POSTS_BACKGROUND_SUCCESS,
            payload: data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: UPDATE_POSTS_BACKGROUND_FAIL,
        });
        return Promise.reject(err);
    }
};

export const updatePostsImages = (id, data) => async (dispatch) => {
    dispatch({
        type: REQUEST_UPDATE_POSTS_IMAGES,
    });
    try {
        const res = await PostsDataService.updatePostsImages(id, data);
        dispatch({
            type: UPDATE_POSTS_IMAGES_SUCCESS,
            payload: data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: UPDATE_POSTS_IMAGES_FAIL,
        });
        return Promise.reject(err);
    }
};

export const updatePostsVideos = (id, data) => async (dispatch) => {
    dispatch({
        type: REQUEST_UPDATE_POSTS_VIDEOS,
    });
    try {
        const res = await PostsDataService.updatePostsVideos(id, data);
        dispatch({
            type: UPDATE_POSTS_VIDEOS_SUCCESS,
            payload: data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: UPDATE_POSTS_VIDEOS_FAIL,
        });
        return Promise.reject(err);
    }
};

export const updatePostsLink = (id, data) => async (dispatch) => {
    dispatch({
        type: REQUEST_UPDATE_POSTS_LINK,
    });
    try {
        const res = await PostsDataService.updatePostsLink(id, data);
        dispatch({
            type: UPDATE_POSTS_LINK_SUCCESS,
            payload: data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: UPDATE_POSTS_LINK_FAIL,
        });
        return Promise.reject(err);
    }
};

export const updatePostsTvs = (id, data) => async (dispatch) => {
    dispatch({
        type: REQUEST_UPDATE_POSTS_TVS,
    });
    try {
        const res = await PostsDataService.updatePostsTvs(id, data);
        dispatch({
            type: UPDATE_POSTS_TVS_SUCCESS,
            payload: data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        dispatch({
            type: UPDATE_POSTS_TVS_FAIL,
        });
        return Promise.reject(err);
    }
};

export const deletePosts = (id) => async (dispatch) => {
    dispatch({
        type: REQUEST_DELETE_POSTS,
    });
    try {
        await PostsDataService.deletePosts(id);
        dispatch({
            type: DELETE_POSTS_SUCCESS,
            payload: { id },
        });
    } catch (err) {
        dispatch({
            type: DELETE_POSTS_FAIL,
        });
        console.log(err);
    }
};

export const deleteCommentsPosts = (id) => async (dispatch) => {
    dispatch({
        type: REQUEST_DELETE_COMMENTS_POSTS,
    });
    try {
        await PostsDataService.deleteCommentsPosts(id);
        dispatch({
            type: DELETE_COMMENTS_POSTS_SUCCESS,
            payload: { id },
        });
    } catch (err) {
        dispatch({
            type: DELETE_COMMENTS_POSTS_FAIL,
        });
        console.log(err);
    }
};

export const deleteReplyCommentsPosts = (id) => async (dispatch) => {
    dispatch({
        type: REQUEST_DELETE_REPLY_COMMENTS_POSTS,
    });
    try {
        await PostsDataService.deleteReplyCommentsPosts(id);
        dispatch({
            type: DELETE_REPLY_COMMENTS_POSTS_SUCCESS,
            payload: { id },
        });
    } catch (err) {
        dispatch({
            type: DELETE_REPLY_COMMENTS_POSTS_FAIL,
        });
        console.log(err);
    }
};
