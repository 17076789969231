import React from 'react';
import { faImages } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';

function ExplorerWidget({ photos }) {
    const history = useNavigate();

    const handlePhotoClick = (id) => {
        history(`/post/${id}`);
    };
    
    return (
        <div className="grid grid-cols-3 pt-0">
            {photos.map(photo => (
                <div onClick={() => handlePhotoClick(photo.id)} key={photo.id} className="relative flex-1 text-center px-0 py-0 m-0.5">
                    <div className="w-full h-40 md:h-44 bg-gray-200 dark:bg-gray-700 overflow-hidden">
                        <img
                            className="w-full h-full object-cover transition-transform duration-300 ease-in-out hover:scale-105 hover:brightness-50"
                            src={photo.images[0].files_small_thumbnail || ''}
                            alt={`Photo ${photo.id}`}
                            onError={(e) => {
                                e.currentTarget.src = '/path/to/fallback-image.jpg';
                            }}
                        />
                    </div>
                    {photo.images.length > 1 && (
                        <div className="absolute top-4 right-4 text-white">
                            <FontAwesomeIcon icon={faImages} size="lg" />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default ExplorerWidget;