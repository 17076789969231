import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveMemories } from "../../../actions/home/home";
import { faHeart, faComments, faSearch, faArrowRight, faMemory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MemoriesPng from '../../../assets/images/memories.png';
import { Link } from 'react-router-dom';

export default function MemoriesPanel() {
    const { isLoading, memoriesList } = useSelector(state => state.home);
    const dispatch = useDispatch();

    useEffect(() => {
        if (memoriesList.length === 0) {
            dispatch(retrieveMemories());
        }
    }, [dispatch, memoriesList.length]);

    if (isLoading) {
        return null;
    }

    if (memoriesList.length > 0) {
        return <div className="max-w-sm mx-auto bg-white dark:bg-dark-second rounded-lg shadow-md">
            <div className="flex items-center p-4">
                <div className="flex-shrink-0">
                    <img src={MemoriesPng} className=' h-[80px] w-[80px]' />
                </div>
                <div className="flex-1 px-4">
                    <p className="text-gray-700 dark:text-white text-base">
                        Temukan kenangan berharga Anda di sini.
                    </p>
                </div>
                <div>
                    <Link to="/memories" className="px-4 py-2 text-white bg-blue-400 dark:bg-gray-800 dark:hover:bg-gray-900 rounded-full hover:bg-blue-500">
                        <FontAwesomeIcon icon={faArrowRight} className="text-white dark:text-dark-txt text-xl" />
                    </Link>
                </div>
            </div>
        </div>;
    }

    return null;
}
