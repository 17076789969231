import React from "react";

function ExplorerPlaceholder() {
    const rows = Array(16).fill().map((_, i) => (
        <div key={i} className="flex-1 text-center px-0 py-0 m-0.5">
            <div className="w-full h-44 object-cover bg-gray-100 dark:bg-gray-700"></div>
        </div>
    ));
    return (
        <div className="grid animate-pulse grid-cols-3 pt-0">
            {rows}
        </div>
    );
}

export default ExplorerPlaceholder;