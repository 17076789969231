import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Contact1 from '../../../assets/images/new/avt-3.jpg';
import { Link } from 'react-router-dom';

class FriendWidget extends Component {
    render() {
        const { t } = this.props
        return (
            <Link to="/profile" className="flex items-center bg-white dark:bg-dark-second mt-2 p-2 rounded cursor-pointer transition hover:bg-gray-200 dark:hover:bg-dark-third">
                <div className="flex ml-2"><img src={Contact1} width="40" height="40" class="rounded-full" /></div>
                <div className="flex flex-col ml-2">
                    <span className="font-medium text-black dark:text-dark-txt">Jessica Koel</span>
                </div>
            </Link>
        );
    }
}

export default withTranslation()(FriendWidget)