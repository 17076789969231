export const REQUEST_RETRIEVE_CURRENT_USER = "REQUEST_RETRIEVE_CURRENT_USER";
export const RETRIEVE_CURRENT_USER_SUCCESS = "RETRIEVE_CURRENT_USER_SUCCESS";
export const RETRIEVE_CURRENT_USER_FAIL = "RETRIEVE_CURRENT_USER_FAIL";
export const REQUEST_RETRIEVE_EXPLORER = "REQUEST_RETRIEVE_EXPLORER";
export const RETRIEVE_EXPLORER_SUCCESS = "RETRIEVE_EXPLORER_SUCCESS";
export const RETRIEVE_EXPLORER_FAIL = "RETRIEVE_EXPLORER_FAIL";
export const REQUEST_RETRIEVE_FORYOU = "REQUEST_RETRIEVE_FORYOU";
export const RETRIEVE_FORYOU_SUCCESS = "RETRIEVE_FORYOU_SUCCESS";
export const RETRIEVE_FORYOU_FAIL = "RETRIEVE_FORYOU_FAIL";
export const REQUEST_RETRIEVE_CONTACTS = "REQUEST_RETRIEVE_CONTACTS";
export const RETRIEVE_CONTACTS_SUCCESS = "RETRIEVE_CONTACTS_SUCCESS";
export const RETRIEVE_CONTACTS_FAIL = "RETRIEVE_CONTACTS_FAIL";
export const REQUEST_RETRIEVE_SUGGESTION_PEOPLE = "REQUEST_RETRIEVE_SUGGESTION_PEOPLE";
export const RETRIEVE_SUGGESTION_PEOPLE_SUCCESS = "RETRIEVE_SUGGESTION_PEOPLE_SUCCESS";
export const RETRIEVE_SUGGESTION_PEOPLE_FAIL = "RETRIEVE_SUGGESTION_PEOPLE_FAIL" 

export const REQUEST_PREVIEWS_URL = "REQUEST_PREVIEWS_URL";
export const RETRIEVE_PREVIEWS_URL_SUCCESS = "RETRIEVE_PREVIEWS_URL_SUCCESS";
export const RETRIEVE_PREVIEWS_URL_FAIL = "RETRIEVE_PREVIEWS_URL_FAIL";

export const REQUEST_RETRIEVE_MEMORIES = "REQUEST_RETRIEVE_MEMORIES";
export const RETRIEVE_MEMORIES_SUCCESS = "RETRIEVE_MEMORIES_SUCCESS";
export const RETRIEVE_MEMORIES_FAIL = "RETRIEVE_MEMORIES_FAIL";