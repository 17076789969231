import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Contact1 from '../../../assets/images/new/avt-3.jpg';
import { Link } from 'react-router-dom';
import { faHeart, faComment, faEllipsisH, faSmile, faPaperPlane, faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class CommentsPostsWidget extends Component {
    render() {
        const { t } = this.props
        return (
            <div className=" bg-white dark:bg-dark-second">
                <div className="flex items-center justify-between py-2">
                    <div className="flex space-x-2 items-center">
                        <div className="relative">
                            <Link to="/profile">  <img src={Contact1} alt="Profile picture" className="w-10 h-10 rounded-full" /></Link>
                            <span className="bg-green-500 w-3 h-3 rounded-full absolute left-0 top-0 border-white border-2"></span>
                        </div>
                        <div>
                            <Link to="/profile">
                                <div className="font-normal text-gray dark:text-dark-txt text-xs">
                                    John Doe
                                </div>
                            </Link>
                            <span className="text-xs text-gray-500">38m</span>
                        </div>
                    </div>

                    <div className="w-8 h-8 grid place-items-center text-xl text-gray-500 hover:bg-gray-200 dark:text-dark-txt dark:hover:bg-dark-third rounded-full cursor-pointer">
                        <FontAwesomeIcon icon={faEllipsisH} className="text-gray-400 text-xl" />
                    </div>
                </div>
                <div className=' ml-10'>
                    <div className="bg-gray-100 dark:bg-dark-third p-2 rounded-2xl text-sm">
                        <Link to="/profile" className="font-semibold block text-black dark:text-dark-txt">John Doe</Link>
                        <span className="text-black dark:text-dark-txt">Lorem ipsum dolor sit amet consectetur, adipisicing elit.In voluptate ipsa animi corrupti unde, voluptatibus expedita suscipit, itaque, laudantium accusantium aspernatur officia repellendus nihil mollitia soluta distinctio praesentium nulla eos?</span>
                    </div>
                    <div className="p-2 text-xs text-gray-500 dark:text-dark-txt">
                        <Link to="/comments-posts/likes"> <span className="font-semibold cursor-pointer">2 Likes</span></Link>
                        <span className="font-semibold cursor-pointer ml-2">3 Reply</span>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex space-x-3">
                            <div className="space-x-2 ml-3 mr-3 justify-center items-center text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">
                                <FontAwesomeIcon icon={faHeart} className="text-red-400 text-xl" />
                            </div>
                            <Link to="" className="space-x-2 ml-3 mr-3 justify-center items-center text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">
                                <FontAwesomeIcon icon={faComment} className="text-gray-400 text-xl" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="py-2 px-4 mt-2 bg-gray-100 dark:bg-dark-third rounded ml-10">
                    <div className="flex space-x-2">
                        <img src={Contact1} alt="Profile picture" className="w-8 h-8 rounded-full" />
                        <div className="flex-1 flex items-center justify-between">
                            <input type="text" placeholder="Write a comment..." className=" bg-white dark:bg-dark-second rounded-full border-0 bg-transparent flex-1 text-sm" />
                        </div>
                        <div className="flex space-x-0 items-center justify-center">
                            <span className="w-8 h-8 grid place-items-center rounded-full bg-green-500  dark:bg-dark-main  cursor-pointer text-white  hover:bg-green-700 dark:hover:bg-dark-second text-md"> <FontAwesomeIcon icon={faPaperPlane} className="text-white text-md" /></span>
                        </div>
                    </div>
                    <div className='flex py-1 pl-10'>
                        <div className="flex space-x-0 items-center justify-start px-1">
                            <span className="w-7 h-7 grid place-items-center rounded-full hover:bg-gray-200 cursor-pointer text-gray-500 dark:text-dark-txt dark:hover:bg-dark-second text-xl"> <FontAwesomeIcon icon={faSmile} className="text-gray-400 text-xl" /></span>
                        </div>
                        <div className="flex space-x-0 items-center justify-start">
                            <span className="w-7 h-7 grid place-items-center rounded-full hover:bg-gray-200 cursor-pointer text-gray-500 dark:text-dark-txt dark:hover:bg-dark-second text-xl"> <FontAwesomeIcon icon={faImage} className="text-gray-400 text-xl" /></span>
                        </div>
                    </div>
                </div>
                <div className='ml-10 mt-2'>
                    <div className="flex items-center justify-between py-2">
                        <div className="flex space-x-2 items-center">
                            <div className="relative">
                                <Link to="/profile">  <img src={Contact1} alt="Profile picture" className="w-10 h-10 rounded-full" /></Link>
                                <span className="bg-green-500 w-3 h-3 rounded-full absolute left-0 top-0 border-white border-2"></span>
                            </div>
                            <div>
                                <Link to="/profile">
                                    <div className="font-normal text-gray dark:text-dark-txt text-xs">
                                        John Doe
                                    </div>
                                </Link>
                                <span className="text-xs text-gray-500">38m</span>
                            </div>
                        </div>

                        <div className="w-8 h-8 grid place-items-center text-xl text-gray-500 hover:bg-gray-200 dark:text-dark-txt dark:hover:bg-dark-third rounded-full cursor-pointer">
                            <FontAwesomeIcon icon={faEllipsisH} className="text-gray-400 text-xl" />
                        </div>
                    </div>
                    <div className='ml-10'>
                        <div className="bg-gray-100 dark:bg-dark-third p-2 rounded-2xl text-sm">
                            <Link to="/profile" className="font-semibold block text-black dark:text-dark-txt">John Doe</Link>
                            <span className="text-black dark:text-dark-txt">Lorem ipsum dolor sit amet consectetur, adipisicing elit.In voluptate ipsa animi corrupti unde, voluptatibus expedita suscipit, itaque, laudantium accusantium aspernatur officia repellendus nihil mollitia soluta distinctio praesentium nulla eos?</span>
                        </div>
                        <div className="p-2 text-xs text-gray-500 dark:text-dark-txt">
                            <Link to="/reply-comments-posts/likes">
                                <span className="font-semibold cursor-pointer">2 Likes</span>
                            </Link>

                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex space-x-3">
                                <div className="space-x-2 ml-3 mr-3 justify-center items-center text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">
                                    <FontAwesomeIcon icon={faHeart} className="text-red-400 text-xl" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(CommentsPostsWidget)