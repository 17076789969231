import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

function ListSuggestionPeople({ peoples }) {
    const { t, i18n } = useTranslation();
    return (peoples.length === 0 ? (
        <p>No People</p>
    ) : (
        <div>
            <div className="flex justify-between items-center px-4 pt-0 text-gray-500 dark:text-dark-txt">
                <span className="font-normal text-sm">{t('label_suggestion')}</span>
            </div>
            <ul className="p-2">
                {peoples &&
                    peoples.map((people, index) => (
                        <li>
                            <Link to="profile" className="flex items-center justify-between space-x-4 p-2 hover:bg-gray-200 dark:hover:bg-dark-third dark:text-dark-txt rounded-lg cursor-pointer">
                                <div className='flex'>
                                    <div className="relative">
                                        <img src={people.profile_img_small} alt="" className="rounded-full  object-cover w-8 h-8" />
                                        {people.is_online === true ? (
                                            <span className="bg-green-500 w-3 h-3 rounded-full absolute left-0 top-0 border-white border-2"></span>
                                        ) : null}
                                    </div>
                                    <div className='ml-4 mt-1'>
                                        <span className="font-normal text-sm">{people.username}</span>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    ))}
            </ul>
        </div>
    ));
}

export default ListSuggestionPeople;